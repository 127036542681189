import { SenderType, StatusStreamer } from "./enum";

export const initialFormItem: IFormItem = {
  value: "",
  error: "",
  firstTimeRender: true,
};

export interface IFormItem {
  value: string | number;
  error: string;
  firstTimeRender: boolean;
}
export interface IProfileForm {
  username: IFormItem;
  twitter: IFormItem;
  instagram: IFormItem;
  youtube: IFormItem;
  cherryLive: IFormItem;
  numOfListeners: IFormItem;
  avaUrl?: IFormItem;
  isValid?: Boolean;
  backgroundUrl?: IFormItem;
  avaThumbUrl?: IFormItem;
  backgroundThumbUrl?: IFormItem;
}

export interface IChatProfileForm {
  chatUsername: IFormItem;
  avaChat: IFormItem;
  avaChatThumb: IFormItem;
}

export interface ITag {
  id: number;
  name?: string;
  created_by?: number;
  number_of_uses?: number;
  isNew?: boolean;
}

export interface IStreamData {
  audience_count: number | null;
  channel_name: string;
  hashtags: ITag[];
  id: number;
  resource_id: string;
  sid: string;
  stream_date: string;
  stream_video_urls: any[];
  background: {
    thumb: {
      url: string;
    };
    url: string;
  };
  streamer_id?: string;
}

export interface IUpdateInfoRequestParams {
  audience_limit: number;
  instagram_account: string;
  name: string;
  twitter_account: string;
  youtube_account: string;
  cherry_live_link?: string;
}

export interface ILiveInfo {
  audience_limit?: number;
  avatar: {
    thumb: {
      url: string;
    };
    url: string;
  };
  background: {
    thumb: {
      url: string;
    };
    url: string;
  };
  instagram_account?: string;
  name?: string;
  cherry_live_link?: string | null;
  twitter_account?: string;
  youtube_account?: string;
  streamer_id?: number;
}

export interface IProfile {
  audience_limit: number;
  avatar: {
    thumb: {
      url: string;
    };
    url: string;
  };
  background: {
    thumb: {
      url: string;
    };
    url: string;
  };
  instagram_account?: string;
  name: string;
  cherry_live_link?: string;
  twitter_account?: string;
  youtube_account?: string;
}

export interface IStreamer {
  audience_count: number;
  lastest_history_id: number;
  id: number;
  profile: IProfile;
  status: StatusStreamer;
  hashtags: ITag[];
}

export interface IFilter {
  page_index?: number;
  page_size?: number;
  keywords?: string;
  hashtag?: string;
  sender_uid?: string | null;
  conversationId?: number;
  keyword?: string;
  isChangeKeyword?: boolean;
}

export interface ICountSocket {
  streamer_id: string;
  audience_count: number;
}

export interface IReceiver {
  id: number;
  uid: string;
  name: string;
  avatar: {
    url?: string;
    thumb: {
      url?: string;
    };
  };
  sender_type: SenderType;
}
export interface IMessage {
  id: number;
  sender: IReceiver;
  conversation_id: number;
  seen: boolean;
  seen_at: string;
  content: string;
  created_at: string;
  updated_at: string;
  attachments?: Array<{
    id: number;
    file_type: string;
    file: {
      url: string;
    };
    message_id: number;
    created_at: string;
    updated_at: string;
  }>;
}
export interface IConversation {
  id: number;
  last_message?: IMessage;
  partner: IReceiver;
}

export type IArrayItem = IMessage | IReceiver | IStreamer | IConversation;

export interface IConversationState {
  listConversationAll: IConversation[];
  hasMore: boolean;
}
