import { Spin } from "antd";
import styles from "./styles.module.scss";

interface IProps {
  isLoading: boolean;
  progress?: string;
}

export default function LoadingUpload({ isLoading, progress }: IProps) {
  return isLoading ? (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContent}>
        <Spin size="large" />
        {progress && <div style={{ marginTop: 10 }}>{progress}%</div>}
      </div>
    </div>
  ) : null;
}
