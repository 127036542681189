const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: `${process.env.REACT_APP_API_DOMAIN}`,
  SOCKET_DOMAIN: `${process.env.REACT_APP_SOCKET_DOMAIN}`,
  APP_LIVE_ID: `${process.env.REACT_APP_APP_LIVE_ID}`,
  LINK_S3: `${process.env.REACT_APP_LINK_S3}`,
  CHERRY_LIVE_URL: `${process.env.REACT_APP_CHERRY_LIVE_URL}`,
  CHERRY_LIVE_SUB_URL: `${process.env.REACT_APP_CHERRY_LIVE_SUB_URL}`,
};

export default configs;
