import CameraIcon from "./camera-icon.svg";
import BackIcon from "./back-icon.svg";
import CloseIcon from "./close-icon.svg";
import HamburgerIcon from "./hamburger-icon.svg";
import InstagramIcon from "./instagram-icon.svg";
import TwitterIcon from "./twitter-icon.svg";
import LogoImg from "./logo-img.svg";
import DropdownIcon from "./dropdown-icon.svg";
import SearchIcon from "./search-icon.svg";
import AvatarDefault from "./avatar-default.svg";
import AvatarDefault2 from "./avatar-default-2.svg";
import AvatarDefault3 from "./avatar-default-3.svg";
import AvatarDefault4 from "./avatar-default-4.svg";
import ActiveIcon from "./active-icon.svg";
import ListenIcon from "./listen-icon.svg";
import LockIcon from "./lock-icon.svg";
import MailIcon from "./mail-icon.svg";
import NextIcon from "./next-icon.svg";
import CameraWhiteIcon from "./camera-icon-white.svg";
import LibraryIcon from "./library-icon.svg";
import PencilIcon from "./pencil-icon.svg";
import FAQImage1 from "./faq-img-1.svg";
import FAQImage2 from "./faq-img-2.svg";
import BackgroundLiveDefault from "./background-live-default.jpg";
import PreIcon from "./pre-icon.svg";
import AvatarPlace from "./avatar-place.svg";
import TwitterIconColor from "./twitter-icon-color.svg";
import InstagramIconColor from "./instagram-icon-color.svg";
import YoutubeIconColor from "./youtube-icon-color.svg";
import HearIcon from "./hear-icon.svg";
import muteSoundIcon from "./mute-sound.png";
import UserIconDefault from "./user-icon-default.png";
import PencilIconBlack from "./pencil-icon-black.svg";
import SendIcon from "./send-icon.svg";
import ThreeDotIcon from "./three-dot-icon.png";
import soundIcon from "./sound.png";
import playIcon from "./play-icon.png";
import pauseIcon from "./pause-icon.png";
import cherryLiveIcon from "./cherry-live-icon.svg";

const ImagesObj = {
  CameraIcon,
  BackIcon,
  CloseIcon,
  HamburgerIcon,
  InstagramIcon,
  TwitterIcon,
  LogoImg,
  DropdownIcon,
  SearchIcon,
  AvatarDefault,
  AvatarDefault2,
  AvatarDefault3,
  AvatarDefault4,
  ActiveIcon,
  ListenIcon,
  LockIcon,
  MailIcon,
  NextIcon,
  CameraWhiteIcon,
  LibraryIcon,
  PencilIcon,
  FAQImage1,
  FAQImage2,
  BackgroundLiveDefault,
  PreIcon,
  AvatarPlace,
  TwitterIconColor,
  InstagramIconColor,
  YoutubeIconColor,
  HearIcon,
  muteSoundIcon,
  UserIconDefault,
  PencilIconBlack,
  SendIcon,
  ThreeDotIcon,
  soundIcon,
  playIcon,
  pauseIcon,
  cherryLiveIcon,
};

export default ImagesObj;
