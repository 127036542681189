import { Button as ButtonAntd } from "antd";
import classNames from "classnames";
import { ButtonType, RoundedType } from "constants/enum";
import { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
interface IProps {
  type?: ButtonType;
  title?: string;
  icon?: string;
  rounded?: RoundedType;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
}

export default function Button({ type, title, icon, className, onClick, rounded, isActive, disabled }: IProps) {
  const btnRef = useRef<HTMLElement | null>();

  useEffect(() => {
    if (!isActive) {
    }
  }, [isActive]);

  return (
    <>
      <ButtonAntd
        onClick={onClick}
        className={classNames(
          styles.btn,
          { [styles[`btn--${type}`]]: !!type },
          { [styles[`btn--${rounded}`]]: !!rounded },
          { [styles["btn--active"]]: isActive },
          { [styles["btn--non-active"]]: typeof isActive === "boolean" && !isActive },
          className
        )}
        icon={icon && <img src={icon} alt="Button icon" />}
        ref={(btn) => (btnRef.current = btn)}
        disabled={!!disabled}
      >
        {title}
      </ButtonAntd>
    </>
  );
}
