export enum Direction {
  Up = 1,
  Down,
  Left,
  Right,
}

export enum ButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ICON = "icon",
  NONE = "none",
  CHAT = "chat",
}

export enum RoundedType {
  LOW = "low",
  HIGH = "hight",
}

export enum Size {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

export enum UploaderType {
  TAKE_PHOTO = "takePhoto",
  UPLOAD_PHOTO = "uploadPhoto",
}

export enum PlatformType {
  WINDOWS = "Windows",
  ANDROID = "Android",
  IOS = "iOS",
}

export enum PositionBackground {
  TOP_LEFT = "top-left",
  BOTTOM = "bottom",
}

export enum BackgroundType {
  NORMAL = "normal",
  LIVE = "live",
}

export enum StreamType {
  USER = "user",
  IDOL = "idol",
}

export enum StatusStreamer {
  ACTIVE = "active",
  STREAMING = "streaming",
  INACTIVE = "inactive",
}

export enum ErrorCode {
  LIVE_STREAM_ON_ANOTHER_DEVICE = "Error.Live_Stream_On_Another_Device",
  LIVE_STREAM_NOT_EXIST = "Error.Live_Stream_Not_Exist",
  LIVE_STREAM_ROOM_FULL = "Error.Live_Stream_Room_Full",
}

export enum KeyBoard {
  SPACE = 32,
}

export enum SNSType {
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  TWITTER = "twitter",
  CHERY_LIVE = "cheryLive",
}

export enum EnvType {
  CMS = "CMS",
  APP = "APP",
  SERVER = "SERVER",
}

export enum LanguageType {
  JA = "ja",
  EN = "en",
  KEY = "cimode",
}

export enum SocketStatus {
  CHANGE_STATUS = "changing_status",
  CHANGE_AUDIENCE_COUNT = "changing_audience_count",
  EXPIRED_STREAMING_TIME = "expired_streaming_time",
  INACTIVE_STREAMER = "inactive_streamer",
}

export enum ChatItemContentType {
  TEXT = "text",
  IMAGE = "image",
  VIDEO = "video",
  MIXED = "mixed",
}

export enum ChatItemDirectionType {
  RECEIVED = "received",
  SENT = "sent",
}

export enum QueryKey {
  UID = "uid",
}

export enum LocalStorageKey {
  UID = "uid",
  CHAT_USERNAME = "chat_username",
}

export enum SenderType {
  IDOL = "idol",
  GUEST = "guest",
}

export enum ConnectionType {
  CONNECTED = "CONNECTED",
}

export enum SessionStorageKey {
  IS_IN_LIVE = "IS_IN_LIVE",
}
