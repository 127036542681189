/* eslint-disable jsx-a11y/alt-text */
import classNames from "classnames";

import { Size } from "constants/enum";
import { useState } from "react";
import styles from "./styles.module.scss";

interface IProps {
  thumb?: string;
  icon?: string;
  content?: string | number;
  size?: Size;
  onClick?(): void;
  disabled?: boolean;
  isImageNeedCircle?: boolean;
}

export default function ButtonCircle({
  icon,
  size = Size.DEFAULT,
  onClick,
  disabled = false,
  isImageNeedCircle = true,
  thumb,
}: IProps) {
  const [isLoadFullImage, setIsLoadFullImage] = useState(false);

  return (
    <div
      className={classNames(styles.btnCircle, {
        [styles[`btnCircle--${size}`]]: !!size,
        [styles[`btnCircle--disabled`]]: !!disabled,
      })}
      onClick={onClick}
    >
      {thumb && (
        <img
          src={thumb}
          style={isImageNeedCircle ? {} : { borderRadius: 0 }}
          className={classNames({ "d-none": isLoadFullImage })}
        />
      )}
      {icon && (
        <img
          onLoad={() => {
            setIsLoadFullImage(true);
          }}
          src={icon}
          style={isImageNeedCircle ? {} : { borderRadius: 0 }}
          className={classNames({ "d-none": !isLoadFullImage })}
        />
      )}
    </div>
  );
}
