import { getStreamerProfile } from "api/profile";
import { useQuery } from "react-query";

interface IProfileResponse {
  id: number;
  audience_limit: number;
  avatar: {
    thumb: {
      url: string;
    };
    url: string;
  };
  background: {
    thumb: {
      url: string;
    };
    url: string;
  };
  instagram_account?: string;
  name: string;
  cherry_live_link?: string;
  twitter_account?: string;
  youtube_account?: string;
  streamer_id: number;
}

export default function useProfile(enabled = false) {
  const {
    data: profile,
    refetch: refetchProfile,
    isFetching: fetchingProfile,
  } = useQuery<IProfileResponse>("profile", getStreamerProfile, {
    enabled,
    cacheTime: 0,
  });
  return { profile, refetchProfile, fetchingProfile };
}
