import { useMutation } from "react-query";

import { LocalStorageKey } from "constants/enum";
import { getUID } from "api/common";

export default function useUID() {
  const { mutate: generateUID, isLoading: loadingUID } = useMutation(() => getUID(), {
    onSuccess: (data: any) => {
      const newUID = data?.data?.uid;
      if (newUID) {
        localStorage.setItem(LocalStorageKey.UID, newUID);
      }
    },
  });
  return { generateUID, loadingUID };
}
