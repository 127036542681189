import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { ChangeEvent } from "react";

import styles from "./styles.module.scss";
import classNames from "classnames";

interface IProps {
  label: string;
  isRequired?: boolean;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  errorMessage?: string;
  onBlur?(): void;
}

export default function InputSNS({
  label,
  isRequired = false,
  value,
  onChange,
  maxLength,
  errorMessage,
  onBlur,
}: IProps) {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <span>{label}</span>
        {isRequired && <span className={styles.required}>{t("rule.required")}</span>}
      </div>
      <div className={styles.input}>
        <Input
          className={classNames({
            [styles[`ant-input-error`]]: !!errorMessage,
          })}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          onBlur={onBlur}
        />
      </div>
      <span className={styles.txtError}>{errorMessage || ""}</span>
    </div>
  );
}
