import { sendGet, sendPost, sendPut } from "./axios";

export const createChatNameAPI = (payload: any) => sendPost("/chatting/senders/create-chatname", payload);
export const createChatAvatarAPI = (payload: any) => sendPut("/chatting/senders/update-avatar", payload);
export const createChatUserAPI = (payload: FormData) => sendPost("/chatting/senders", payload);
export const getListReceiversAPI = (params: any) => sendGet("/chatting/receivers", params);
export const getListConversationsAPI = (params: any) => sendGet("/chatting/conversations", params);
export const getListMessagesOfConversationAPI = (params: any) => {
  return sendGet(`/chatting/conversations/${params?.conversationId}/messages`, params);
};
export const createConversationAPI = (payload: { sender_uid: any; receiver_uid: any }) =>
  sendPost(`/chatting/conversations`, payload);
export const getSenderInfoAPI = (params: any) => sendGet(`/chatting/senders/information`, params);
export const createMessageAPI = (
  conversationId: number,
  payload: FormData,
  { withProgress } = { withProgress: false }
) => sendPost(`/chatting/conversations/${conversationId}/messages`, payload, {}, { withProgress });
export const seenAllMessagesAPI = (params: any) => {
  const newParams = {
    sender_uid: params?.sender_uid,
    message_sender_uid: params?.message_sender_uid,
  };
  return sendPost(`/chatting/conversations/${params?.conversation_id}/messages/seen-all-message`, newParams);
};

export const seenOneMessageAPI = (params: any) => {
  const newParams = {
    sender_uid: params?.sender_uid,
    message_sender_uid: params?.message_sender_uid,
  };
  return sendPost(
    `/chatting/conversations/${params?.conversation_id}/messages/${params?.message_id}/seen-one-message`,
    newParams
  );
};
