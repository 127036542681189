import { Spin } from "antd";

interface IProps {
  isLoading: boolean;
  progress?: string | number;
}

export default function LoadingApi({ isLoading, progress }: IProps) {
  return isLoading ? (
    <div className="spin-container">
      <Spin className="spin-loading-screen" size="large" />
      {progress && <span>{progress}%</span>}
    </div>
  ) : null;
}
