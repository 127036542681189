import { PlatformType } from "constants/enum";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getPlatform = () => {
  const platform = [PlatformType.WINDOWS, PlatformType.ANDROID, PlatformType.IOS];
  for (let i = 0; i < platform.length; i++) {
    if (navigator.userAgent.includes(platform[i])) {
      return platform[i];
    }
  }
};
