import ImagesObj from "assets/images";
import ButtonCircle from "components/ButtonCircle";

import { Size } from "constants/enum";
import useScroll from "utils/hooks/useScroll";
import styles from "./styles.module.scss";

interface IProps {
  onClick(): void;
  disabled?: boolean;
  isAbsolute?: boolean;
  needCheckingFooterShown?: boolean;
}

export default function NextButtonCircle({
  onClick,
  disabled = false,
  isAbsolute = true,
  needCheckingFooterShown = true,
}: IProps) {
  const { isFooterShown } = useScroll();
  return !isAbsolute ? (
    <div className={styles.btnNextWrapperRelative}>
      <ButtonCircle disabled={disabled} icon={ImagesObj.NextIcon} size={Size.SMALL} onClick={onClick} />
    </div>
  ) : (
    <div
      className={styles.btnNextWrapperAbsolute}
      style={{ bottom: !needCheckingFooterShown || isFooterShown ? 150 : 50 }}
    >
      <ButtonCircle disabled={disabled} icon={ImagesObj.NextIcon} size={Size.SMALL} onClick={onClick} />
    </div>
  );
}
