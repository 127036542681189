import { useState, useEffect, useCallback } from "react";

export default function useScroll() {
  const [y, setY] = useState(window.scrollY);
  const [isFooterShown, setIsFooterShown] = useState(true);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      const root = document.getElementById("root");
      if (y > window.scrollY && !isFooterShown && window.innerHeight + window.pageYOffset < (root?.scrollHeight || 0)) {
        // Scrolling up
        setIsFooterShown(true);
      } else if (
        window.scrollY > 0 &&
        y < window.scrollY &&
        isFooterShown &&
        window.innerHeight + window.pageYOffset < (root?.scrollHeight || 0)
      ) {
        // Scrolling down
        setIsFooterShown(false);
      }
      setY(window.scrollY);
    },
    [y, isFooterShown]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return { y, isFooterShown };
}
