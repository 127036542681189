import { Modal } from "antd";
import classNames from "classnames";
import Avatar from "components/Avatar";
import { ChatItemContentType } from "constants/enum";
import { IMessage, IReceiver } from "constants/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertDateJP } from "utils/helper";
import styles from "../styles.module.scss";

interface IProps extends IMessage {
  onClick?(): void;
  isShowTime?: boolean;
  senderInfo: IReceiver;
}

function ChatItem({ isShowTime = false, senderInfo, onClick, ...chatItem }: IProps) {
  const { t } = useTranslation();
  const isThisPersonOnTheRight = chatItem?.sender?.id === senderInfo?.id || chatItem?.sender?.uid === senderInfo?.uid;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  let currentType = ChatItemContentType.TEXT;
  if (chatItem?.attachments && chatItem?.attachments?.length > 0) {
    // This can be for multiple types
    currentType = chatItem?.attachments[0]?.file_type as any;
  }

  const renderContent = (type: ChatItemContentType, currentItem: any) => {
    const handleCancel = () => setPreviewOpen(false);
    if (type === ChatItemContentType.IMAGE) {
      return (
        <div>
          <Modal visible={previewOpen} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%", marginTop: 20 }} src={previewImage} />
          </Modal>
          <img
            onClick={() => {
              setPreviewImage(currentItem?.attachments[0]?.file?.url);
              setPreviewOpen(true);
            }}
            style={{ maxWidth: "100%" }}
            src={currentItem?.attachments[0]?.file?.url}
            alt="content"
          />
        </div>
      );
    }
    if (type === ChatItemContentType.VIDEO) {
      return (
        <video src={currentItem?.attachments[0]?.file?.url} controls={true} className={styles.contVideoBubble} />
      );
    }
    return <div className={styles.contTxtBubble}>
      {currentItem?.content}
    </div>;
  };
  return (
    <div>
      {isShowTime && <div className={styles.itemChatTime}>{convertDateJP(chatItem?.created_at, true)}</div>}
      <div
        onClick={onClick}
        className={classNames(styles.itemChat, {
          [styles[`itemChat--sent`]]: isThisPersonOnTheRight,
        })}
      >
        <div
          className={classNames(styles.itemAvaBubble, {
            [styles[`itemAvaBubble--sent`]]: isThisPersonOnTheRight,
          })}
        >
          {!isThisPersonOnTheRight && <Avatar avatar={chatItem?.sender?.avatar?.thumb?.url} />}

          <div
            className={classNames(styles.itemBubble, {
              [styles[`itemBubble--received`]]: !isThisPersonOnTheRight,
              [styles[`itemBubble--sent`]]: isThisPersonOnTheRight,
            })}
          >
            {renderContent(currentType, chatItem)}
          </div>
        </div>
        {isThisPersonOnTheRight && (
          <div className={styles.wrapperNameSeen}>
            <div className={classNames(styles.txtSeen)}>{chatItem?.seen ? `${t("chat.seen")}・` : ""}</div>
            <div style={{ marginLeft: 0 }} className={classNames(styles.txtName)}>
              {chatItem?.sender?.name}
            </div>
          </div>
        )}
        {!isThisPersonOnTheRight && (
          <div className={styles.wrapperNameSeen}>
            <div className={classNames(styles.txtName)}>{chatItem?.sender?.name}</div>
            {/* <div className={classNames(styles.txtSeen)}>{chatItem?.seen ? `・${t("chat.seen")}` : ""}</div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatItem;
