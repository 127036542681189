import { useEffect, useState } from "react";
import _ from "lodash";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ImagesObj from "assets/images";
import PageHeader from "components/PageHeader";
import { history } from "App";
import PageFooter from "components/PageFooter";
import { LocalStorageKey } from "constants/enum";
import PageFillName from "./PageFillName";
import Cookies from "js-cookie";
import { IChatProfileForm, initialFormItem } from "constants/interface";
import { createChatUserAPI } from "api/chat";
import SocketMesModal from "pages/SocketMesModal";

export default function ChatFillName() {
  const { t } = useTranslation();
  const access_token = Cookies.get("access_token");
  const uid = localStorage.getItem(LocalStorageKey.UID);
  const chat_username = localStorage.getItem(LocalStorageKey.CHAT_USERNAME);
  const [form, setForm] = useState<IChatProfileForm>({
    chatUsername: { ...initialFormItem },
    avaChat: { ...initialFormItem, firstTimeRender: false },
    avaChatThumb: { ...initialFormItem, firstTimeRender: false },
  });

  const onSubmitName = async (username: string | number) => {
    try {
      setForm({
        ...form,
        chatUsername: {
          ...form.chatUsername,
          value: username,
        },
      });
      const fmData = new FormData();
      fmData.append("name", `${username}`);
      fmData.append("uid", uid || "");
      await createChatUserAPI(fmData);
      localStorage.setItem(LocalStorageKey.CHAT_USERNAME, `${username}`);
      history.push("/chat-history", { firstTimeCreateUser: true });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (access_token || (!access_token && chat_username)) {
      history.push("/chat-history");
      return;
    }
    return;
  }, [chat_username, access_token]);

  return (
    <div className={styles.container}>
      <SocketMesModal />
      <PageHeader
        iconLeft={ImagesObj.BackIcon}
        onPressLeft={() => {
          history.back();
        }}
      />
      <div className={styles.contentContainer}>
        <div className={styles.txtTitle}>{t("chat.title")}</div>
        <PageFillName form={form} handleSubmit={onSubmitName} />
      </div>
      <PageFooter />
    </div>
  );
}
