import _, { isNumber } from "lodash";
import styles from "./styles.module.scss";
import PageHeader from "components/PageHeader";
import ImagesObj from "assets/images";
import { history } from "App";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import PageFooter from "components/PageFooter";
import useScroll from "utils/hooks/useScroll";
import NextButtonCircle from "components/NextButtonCircle";
import { handleErrorMessage } from "i18n";
import { useEffect, useState } from "react";
import { getCompanyAPI } from "api/company";
import useCheckStopLive from "utils/hooks/useCheckStopLive";
import ButtonCreateLive from "components/ButtonCreateLive";

interface IProps {
  id?: number;
  info?: string;
}

export default function InfoCompany() {
  const { isFooterShown } = useScroll();
  const { toggleSideNav } = useToggleSideNav();
  const { t } = useTranslation();
  const [currentCompany, setCurrentCompany] = useState<IProps>({
    id: undefined,
    info: "",
  });
  const { muteStopLive } = useCheckStopLive();
  const handleGetCompany = async () => {
    try {
      const companyResult = await getCompanyAPI();
      setCurrentCompany(companyResult);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  useEffect(() => {
    muteStopLive();
    handleGetCompany();
  }, []);

  return (
    <div className={styles.container}>
      <PageHeader
        iconLeft={ImagesObj.HamburgerIcon}
        onPressLeft={toggleSideNav}
        imgTitle={ImagesObj.LogoImg}
        hasShadow={true}
        RightComponent={<ButtonCreateLive />}
      />
      <div className={styles.contentContainer} dangerouslySetInnerHTML={{ __html: currentCompany?.info || "" }} />
      <NextButtonCircle onClick={() => history.push("/")} />
      <PageFooter customStyles={{ bottom: isFooterShown ? 0 : -150, transition: "bottom 0.3s" }} />
    </div>
  );
}
