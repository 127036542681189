import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import styles from "./styles.module.scss";
import ImagesObj from "assets/images";
import PageHeader from "components/PageHeader";
import { history } from "App";
import Button from "components/Button";
import { useLocation } from "react-router-dom";
import { IFilter, IMessage } from "constants/interface";
import ChatItem from "./components/ChatItem";
import { ChatItemContentType, LocalStorageKey, UploaderType } from "constants/enum";
import { Input, Spin } from "antd";
import ChatUploader from "./components/ChatUploader";
import { createMessageAPI, getListMessagesOfConversationAPI, getSenderInfoAPI, seenAllMessagesAPI } from "api/chat";
import useFilter from "utils/hooks/useFilter";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { acceptedImageType, acceptedVideoType } from "constants/staticData";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { on } from "utils/events";
import LoadingUpload from "components/LoadingUpload";
import useSocket from "utils/hooks/useSocket";
import { check30s, processGroupMessages } from "utils/helper";
import storage from "utils/helper/storage";
import Modal from "components/Modal";
import SocketMesModal from "pages/SocketMesModal";

interface IState {
  messagesList: IMessage[];
  hasMore: boolean;
}

export default function ChatDetails() {
  const uid = localStorage.getItem(LocalStorageKey.UID);
  const access_token = Cookies.get("access_token");
  const currentUid = access_token ? undefined : uid;
  const location: any = useLocation();
  const messageEndElement = useRef<HTMLDivElement>();
  const searchParams = new URLSearchParams(location?.search);
  const conversationId = Number(searchParams.get("conversation-id"));
  const { t } = useTranslation();
  const defaultFilter: IFilter = {
    page_index: 1,
    page_size: 10,
    conversationId,
  };
  const { filter: chatFilter, handleFilterChange } = useFilter(defaultFilter);
  const [state, setState] = useState<IState>({
    messagesList: [],
    hasMore: false,
  });
  const [currentContent, setCurrentContent] = useState("");
  const [senderInfo, setSenderInfo] = useState<any>();
  const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState("");
  const [openCancelWarning, setOpenCancelWarning] = useState(false);
  const [openCreateWarning, setOpenCreateWarning] = useState(false);

  const infoIdolLive = storage.getItem("infoIdolLive")?.data;

  const handleCancelClick = () => {
    if (infoIdolLive) {
      setOpenCancelWarning(true);
    } else {
      history.replace("/");
    }
  };
  const handleCreateClick = () => {
    if (infoIdolLive) {
      setOpenCreateWarning(true);
    } else {
      history.push("/pre-live-stream");
    }
  };

  const scrollToBottom = () => {
    (messageEndElement.current as any)?.scrollIntoView({ behavior: "smooth" });
  };

  const loadMoreData = () => {
    handleFilterChange({ page_index: (chatFilter.page_index || 0) + 1 });
  };

  const handleReceivedMessage = (messageResult: any) => {
    let timeout;
    if (
      messageResult?.content ||
      (!messageResult?.content && messageResult?.attachments?.file_type === ChatItemContentType.IMAGE)
    ) {
      timeout = 200;
    } else {
      timeout = 1000;
    }
    setTimeout(() => {
      setState({
        ...state,
        messagesList: [
          {
            ...messageResult,
            isShowTime: true,
          },
          ...state.messagesList,
        ],
      });
    }, timeout);
  };

  const handleSeenMessage = () => {
    const messagesListAfterSeenAll = state.messagesList?.map((messageItem) => {
      return {
        ...messageItem,
        seen: true,
      };
    });
    setState({
      ...state,
      messagesList: messagesListAfterSeenAll,
    });
  };

  useSocket({
    conversationId,
    handleReceivedMessage,
    handleSeenMessage,
  });

  const getSenderInfo = async () => {
    try {
      const params = {
        uid: currentUid,
      };
      const resultSenderInfo = await getSenderInfoAPI(params);
      setSenderInfo(resultSenderInfo?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendMessage = async ({
    curFileList,
    sender_uid,
    content,
  }: {
    curFileList?: Array<any>;
    sender_uid?: string;
    content?: string;
  }) => {
    try {
      let type = "";
      if (curFileList && acceptedImageType?.includes(curFileList[0]?.file?.type)) {
        type = ChatItemContentType.IMAGE;
      }
      if (curFileList && acceptedVideoType?.includes(curFileList[0]?.file?.type)) {
        type = ChatItemContentType.VIDEO;
      }
      if (curFileList && type) {
        setLoadingSendMessage(true);
      }
      const fmData = new FormData();
      sender_uid && fmData.append("sender_uid", sender_uid);
      content && fmData.append("content", content);
      curFileList && type && fmData.append("file_type", type);
      curFileList && fmData.append("file", curFileList[0]?.file);
      await createMessageAPI(conversationId, fmData, { withProgress: !!curFileList && !!type });
      setCurrentContent("");
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSendMessage(false);
      scrollToBottom();
    }
  };

  const handleSeenAllMessages = async ({
    messagesList,
    conversation_id,
  }: {
    messagesList?: Array<IMessage>;
    conversation_id?: number;
  }) => {
    try {
      const params = {
        conversation_id,
        sender_uid: access_token ? undefined : uid,
        message_sender_uid: messagesList?.[0]?.sender?.uid,
      };
      await seenAllMessagesAPI(params);
    } catch (err) {
      console.log(err);
    }
  };

  const { data: messages, isFetching: loadingMessages }: any = useQuery(
    ["messages", chatFilter],
    () => {
      return getListMessagesOfConversationAPI(chatFilter);
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    getSenderInfo();
  }, []);

  useEffect(() => {
    if (messages?.data && !loadingMessages) {
      const resultArr = processGroupMessages({
        curMessages: state.messagesList,
        newMessages: messages?.data,
      });
      setState({
        messagesList: resultArr,
        hasMore: messages?.data?.length === chatFilter.page_size,
      });
    }
  }, [loadingMessages]);

  useEffect(() => {
    if (state.messagesList?.length > 0 && !!conversationId) {
      handleSeenAllMessages({ messagesList: state.messagesList, conversation_id: conversationId });
    }
  }, [conversationId, state.messagesList?.length]);

  useEffect(() => {
    on("UPLOAD_FILE", (event: any) => {
      if (event?.detail !== currentProgress) {
        setCurrentProgress(event?.detail);
      }
    });
  });

  return (
    <div className={styles.container}>
      <SocketMesModal />
      <LoadingUpload isLoading={loadingSendMessage} progress={currentProgress} />
      <PageHeader
        iconLeft={ImagesObj.BackIcon}
        onPressLeft={() => {
          history.back();
        }}
        imgTitle={ImagesObj.LogoImg}
        onPressTitle={handleCancelClick}
        hasShadow={true}
        RightComponent={
          <Button title={!!access_token ? t("btn.createLive") : t("btn.login")} onClick={handleCreateClick} />
        }
      />
      <InfiniteScroll
        className={styles.contChat}
        dataLength={state.messagesList?.length}
        next={loadMoreData}
        hasMore={state.hasMore}
        height={window.innerHeight / window.innerWidth > 2 ? "80vh" : "65vh"}
        inverse={true}
        loader={<Spin className={styles.spinLoader} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />}
      >
        {state.messagesList?.map((item: IMessage) => (
          <ChatItem key={item.id} onClick={() => {}} senderInfo={senderInfo} {...item} />
        ))}
      </InfiniteScroll>
      <div className={styles.inputChatWrapper}>
        <ChatUploader
          type={UploaderType.UPLOAD_PHOTO}
          handleOk={async (curFileList) => {
            if (curFileList && curFileList?.length > 0) {
              handleSendMessage({
                sender_uid: `${currentUid}`,
                content: currentContent.trim(),
                curFileList,
              });
            }
          }}
        >
          <div className={styles.btnAction}>
            <img src={ImagesObj.CameraIcon} alt="btnUploadImage" />
          </div>
        </ChatUploader>
        <Input.TextArea
          className={styles.input}
          placeholder={t("chat.inputPlaceholder")}
          rows={1}
          value={currentContent}
          onChange={(event) => {
            setCurrentContent(event.target.value);
          }}
        />
        <div
          className={styles.btnAction}
          onClick={async () => {
            if (currentContent.trim()) {
              handleSendMessage({
                sender_uid: `${currentUid}`,
                content: currentContent.trim(),
              });
            }
          }}
        >
          <img src={ImagesObj.SendIcon} alt="btnSendMessage" />
        </div>
      </div>
      {openCreateWarning && (
        <Modal
          isVisibleModal={openCreateWarning}
          setIsVisibleModal={setOpenCreateWarning}
          className="alert-create-modal"
        >
          <div className="alert-create-content">
            <div className="alert-create-text">{t("alert.create")}</div>
          </div>
        </Modal>
      )}
      {openCancelWarning && (
        <Modal
          isVisibleModal={openCancelWarning}
          setIsVisibleModal={setOpenCancelWarning}
          className="alert-cancel-modal"
          isTwoOption
          okText={t("btn.cancel")}
          onOk={() => {
            history.replace("/");
          }}
        >
          <div className="alert-cancel-content">
            <div className="alert-cancel-text">
              {check30s(infoIdolLive?.started_at) ? t("alert.cancel30s") : t("alert.cancel")}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
