import { handleErrorMessage } from "./../i18n/index";
import Axios from "axios";
import configs from "constants/config";
import Cookies from "js-cookie";
import { logout } from "utils/helper/authentication";
import { ErrorCode } from "constants/enum";
import { trigger } from "utils/events";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    const access_token = Cookies.get("access_token");
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    const originalConfig = error.config;
    if (error.response.status !== 401) {
      if (
        error?.response?.data?.errorCode === ErrorCode.LIVE_STREAM_ON_ANOTHER_DEVICE ||
        error?.response?.data?.errorCode === ErrorCode.LIVE_STREAM_NOT_EXIST ||
        error?.response?.data?.errorCode === ErrorCode.LIVE_STREAM_ROOM_FULL
      ) {
        return Promise.reject(error);
      }
      handleErrorMessage(error);
      return Promise.reject(error);
    }
    const refresh_token = Cookies.get("refresh_token");
    if (!refresh_token) {
      logout();
      return Promise.reject(error);
    }
    return Axios.post(`${configs.API_DOMAIN}/auth/request-access-token`, {
      refresh_token,
    })
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.data;
          Cookies.set("access_token", data.access_token);
          originalConfig.headers.Authorization = `Bearer ${data.access_token}`;
          return Axios(originalConfig);
        } else {
          logout();
          return Promise.reject(error);
        }
      })
      .catch(() => {
        logout();
        return Promise.reject(error);
      });
  }
);

export const sendGet = (url: string, params?: any) => axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any, { withProgress } = { withProgress: false }) =>
  axiosInstance
    .post(url, params, {
      params: queryParams,
      onUploadProgress: withProgress
        ? (progressEvent) => {
            const total = progressEvent?.total;
            const current = progressEvent?.loaded;
            let percentCompleted = ((current / total) * 100).toFixed(0);
            trigger("UPLOAD_FILE", percentCompleted);
          }
        : undefined,
    })
    .then((res) => res.data);
export const sendPut = (url: string, params?: any) => axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) => axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) => axiosInstance.delete(url, { params }).then((res) => res.data);
