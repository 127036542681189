import { Upload, UploadFile, Modal, Button } from "antd";
import { ChatImagesVideosList } from "components/ChatImagesVideosList";
import { UploaderType } from "constants/enum";
import { acceptedImageType, acceptedVideoType, LIMIT_FILE_SIZE, LIMIT_VIDEO_FILE_SIZE } from "constants/staticData";
import { isEqual } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles.module.scss";

interface IProps {
  type: UploaderType;
  isMultiple?: boolean;
  handleOk?(curFileList: Array<any>): void;
  handleErrorText?({ errorText }: { errorText: Array<string> }): void;
  children: ReactNode;
}

function ChatUploader({ children, type, isMultiple = false, handleErrorText, handleOk }: IProps) {
  const { t } = useTranslation();
  let captureType: any;
  if (type === UploaderType.TAKE_PHOTO) {
    captureType = "environment";
  }
  const [curFileList, setCurFileList] = useState<any[]>([]);
  const [curErrorTextArr, setCurErrorTextArr] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const getBase64 = async (img: any) => {
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(img);
    });
    return reader?.result;
  };

  const handleFileChange = async (currentFile: any) => {
    let url;
    let errorText = "";
    const isFileImage = acceptedImageType.includes(currentFile?.type);
    const isFileVideo = acceptedVideoType.includes(currentFile?.type);

    if (!isFileImage && !isFileVideo) {
      errorText = t("common.wrongFormatFileDanger");
    } else if (isFileImage && currentFile?.size >= LIMIT_FILE_SIZE) {
      errorText = t("common.largeImageFileDanger");
    } else if (isFileVideo && currentFile?.size >= LIMIT_VIDEO_FILE_SIZE) {
      errorText = t("common.largeVideoFileDanger");
    }
    url = await getBase64(currentFile);

    return { url, file: currentFile, errorText };
  };

  const handleRemoveWithIndex = (indexToDelete: number) => {
    const newFileList = curFileList.slice();
    newFileList.splice(indexToDelete, 1);
    setCurFileList(newFileList);
  };

  const handleRemove = (file: UploadFile) => {
    const index = curFileList.indexOf(file);
    handleRemoveWithIndex(index);
  };

  const handleBeforeUpload = async (_: UploadFile, fileList: UploadFile[]) => {
    const fileListAfterChanged = [];
    for (let fileItem of fileList) {
      const fileAfterChanged = await handleFileChange(fileItem);
      fileListAfterChanged.push(fileAfterChanged);
    }
    if (!isEqual(curFileList, fileListAfterChanged)) {
      setCurFileList([...curFileList, ...fileListAfterChanged]);
    }
    return false;
  };

  useEffect(() => {
    if (curFileList?.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [curFileList]);

  return (
    <div>
      <Modal
        closable={false}
        maskClosable={false}
        visible={isVisible}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setCurFileList([]);
              setIsVisible(false);
            }}
          >
            Cancel
          </Button>,
          curFileList?.map((item) => item?.errorText)?.find((item) => !!item) ? (
            ""
          ) : (
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                if (handleOk) {
                  handleOk(curFileList);
                }
                setCurFileList([]);
                setIsVisible(false);
              }}
            >
              Send
            </Button>
          ),
        ]}
      >
        {curFileList?.length > 0 && (
          <ChatImagesVideosList
            isDeletable={true}
            data={curFileList.map((item) => {
              return {
                url: item?.url,
                type: item?.file?.type,
              };
            })}
            handleDelete={(indexToDelete: number) => {
              handleRemoveWithIndex(indexToDelete);
            }}
            isWrapped={true}
          />
        )}
        {curFileList?.map((item, index) => {
          if (item?.errorText) {
            return (
              <div key={`${index}`} className={styles.txtError}>
                {item?.errorText}
              </div>
            );
          }
          return null;
        })}
      </Modal>
      <Upload
        multiple={isMultiple}
        customRequest={() => null}
        className={styles.uploadCustom}
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        onPreview={() => null}
        onRemove={handleRemove}
        beforeUpload={handleBeforeUpload}
        accept={`
          ${acceptedImageType.join(", ")}, 
          ${acceptedVideoType.join(", ")}
        `}
        capture={captureType}
      >
        <div>{children}</div>
      </Upload>
    </div>
  );
}

export default ChatUploader;
