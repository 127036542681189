import { useEffect, useState } from "react";
import _, { isEqual } from "lodash";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { IFilter, IReceiver } from "constants/interface";
import ImagesObj from "assets/images";
import PageHeader from "components/PageHeader";
import { history } from "App";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin, List, Empty, Space, Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import useFilter from "utils/hooks/useFilter";
import { useQuery } from "react-query";
import classNames from "classnames";
import { createConversationAPI, getListReceiversAPI } from "api/chat";
import Avatar from "components/Avatar";
import { LocalStorageKey } from "constants/enum";
import Cookies from "js-cookie";
import SocketMesModal from "pages/SocketMesModal";

interface IState {
  receiversList: IReceiver[];
  hasMore: boolean;
}

const defaultFilter: IFilter = {
  page_index: 1,
  page_size: 15,
};

export default function ChatNew() {
  const { t } = useTranslation();
  const uid = localStorage.getItem(LocalStorageKey.UID);
  const access_token = Cookies.get("access_token");
  const { filter, handleFilterChange, handleSearch: handleReceiversSearch } = useFilter(defaultFilter);
  const [state, setState] = useState<IState>({
    receiversList: [],
    hasMore: false,
  });
  const [chosenRecipients, setChosenRecipients] = useState<Array<any>>([]);
  const [isCustomLoading, setIsCustomLoading] = useState(true);

  const loadMoreData = () => {
    handleFilterChange({ page_index: (filter.page_index || 0) + 1 });
  };

  const { data: receivers, isFetching: loadingReceivers }: any = useQuery(
    ["receivers", filter],
    () => getListReceiversAPI(filter),
    {
      cacheTime: 0,
    }
  );

  const onChooseRecipient = (item: IReceiver, isMultiple = false) => {
    // Multiple choices mode
    if (isMultiple) {
      if (chosenRecipients.indexOf(item) >= 0) {
        const newChosenRecipients = chosenRecipients.filter((recItem: IReceiver) => !isEqual(recItem, item));
        setChosenRecipients(newChosenRecipients);
        return;
      }
      const newChosenRecipients = [...chosenRecipients, item];
      setChosenRecipients(newChosenRecipients);
      return;
    }
    // Single choice mode
    const newChosenRecipients = [item];
    setChosenRecipients(newChosenRecipients);
  };

  const onGoToChatDetails = async () => {
    try {
      if (chosenRecipients.length > 0) {
        const resultCreateConversation = await createConversationAPI({
          sender_uid: access_token ? undefined : String(uid),
          receiver_uid: String(chosenRecipients[0]?.uid),
        });
        history.push(`/chat-details?conversation-id=${resultCreateConversation?.data?.id}`, { chosenRecipients });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (receivers?.data && !loadingReceivers) {
      setState({
        receiversList: [...state.receiversList, ...receivers?.data],
        hasMore: receivers?.data?.length === filter.page_size,
      });
      setIsCustomLoading(false);
    }
  }, [loadingReceivers]);

  return (
    <div className={styles.container}>
      <SocketMesModal />
      <PageHeader
        iconLeft={ImagesObj.BackIcon}
        onPressLeft={() => {
          history.back();
        }}
        title={t("chat.newMessage")}
        iconRight={chosenRecipients.length === 0 ? ImagesObj.PencilIcon : ImagesObj.PencilIconBlack}
        onPressRight={onGoToChatDetails}
      />
      <div className={styles.receiverSearch}>
        <Input
          placeholder={t("chat.placeHolderKeyword")}
          onChange={(e) => {
            setIsCustomLoading(true);
            setState({
              ...state,
              receiversList: [],
              hasMore: false,
            });
            handleReceiversSearch.keywordSearch(e);
          }}
          prefix={<SearchOutlined />}
        />
      </div>
      <div className={styles.recipientsContainer}>
        <div className={styles.txtRecipient}>{t("chat.recipient")}</div>
        <InfiniteScroll
          dataLength={state.receiversList.length || 0}
          next={loadMoreData}
          hasMore={state.hasMore}
          loader={<Spin className={styles.spinLoader} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />}
        >
          <List
            className={styles.recipientsList}
            loading={loadingReceivers || isCustomLoading}
            dataSource={state.receiversList || []}
            locale={{ emptyText: <Empty description={t("common.emptyText")} /> }}
            renderItem={(item: IReceiver) => {
              return (
                <List.Item
                  onClick={() => {
                    onChooseRecipient(item);
                  }}
                  className={styles.recipientsListItem}
                  key={item.id}
                >
                  <Space direction="horizontal" size="large">
                    <Avatar avatar={item?.avatar?.url} avatarThumb={item?.avatar?.thumb?.url} needBehindIcon={false} />
                    <div className={styles.contTxtName}>
                      <span>{item?.name}</span>
                    </div>
                  </Space>
                  <div
                    className={classNames(styles.btnCircleStatus, {
                      [styles[`btnCircleStatus--chosen`]]: !!chosenRecipients.includes(item),
                    })}
                  />
                </List.Item>
              );
            }}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
}
