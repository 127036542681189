import { useEffect, useState } from "react";
import _, { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { IChatProfileForm, IFormItem, initialFormItem } from "constants/interface";
import InputSNS from "components/InputSNS";
import Validation from "utils/helper/validate";
import NextButtonCircle from "components/NextButtonCircle";

interface IProps {
  form: IChatProfileForm;
  handleSubmit(chatUsername: string | number): void;
}

export default function PageFillName({ form, handleSubmit }: IProps) {
  const { t } = useTranslation();
  const [currentForm, setForm] = useState<{
    chatUsername: IFormItem;
    isValid?: boolean;
  }>({
    chatUsername: initialFormItem,
  });

  useEffect(() => {
    if (!isEqual(form, currentForm)) {
      setForm({
        ...currentForm,
        ...form,
      });
    }
  }, [form]);

  useEffect(() => {
    const newIsValid = !currentForm.chatUsername.error && !currentForm.chatUsername.firstTimeRender;
    setForm({
      ...currentForm,
      isValid: newIsValid,
    });
  }, [currentForm.chatUsername.error, currentForm.chatUsername.firstTimeRender]);

  return (
    <div>
      <InputSNS
        label={t("chat.username")}
        isRequired
        value={currentForm.chatUsername.value}
        onChange={(event) => {
          const value = event.target.value;
          const { error } = Validation.validateUsernameLogin({ value });
          setForm({
            ...currentForm,
            chatUsername: { ...currentForm.chatUsername, error, firstTimeRender: false, value },
          });
        }}
        maxLength={999}
        errorMessage={currentForm.chatUsername.error}
        onBlur={() => {
          const value = currentForm.chatUsername.value.toString().trim();
          const { error } = Validation.validateUsernameLogin({ value });
          setForm({
            ...currentForm,
            chatUsername: { ...currentForm.chatUsername, error, firstTimeRender: false, value },
          });
        }}
      />
      <NextButtonCircle
        isAbsolute={true}
        disabled={!currentForm.isValid}
        onClick={() => {
          if (currentForm.isValid) {
            handleSubmit(currentForm.chatUsername.value);
            return;
          }
        }}
      />
    </div>
  );
}
