import { useEffect, useState } from "react";
import _ from "lodash";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { IFilter, IConversation, IConversationState } from "constants/interface";
import ImagesObj from "assets/images";
import PageHeader from "components/PageHeader";
import { history } from "App";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin, List, Empty, Space, Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import useFilter from "utils/hooks/useFilter";
import { useQuery } from "react-query";
import { getListConversationsAPI, getSenderInfoAPI } from "api/chat";
import { LocalStorageKey } from "constants/enum";
import Cookies from "js-cookie";
import Avatar from "components/Avatar";
import classNames from "classnames";
import useSocket from "utils/hooks/useSocket";
import { convertTimeToAgo } from "utils/helper";
import { useLocation } from "react-router-dom";
import SocketMesModal from "pages/SocketMesModal";

const defaultConversationState: IConversationState = {
  listConversationAll: [],
  hasMore: false,
};

export default function ChatHistory() {
  const { t } = useTranslation();
  const [isCustomLoading, setIsCustomLoading] = useState(true);
  const access_token = Cookies.get("access_token");
  const [conversationState, setConversationState] = useState<IConversationState>(defaultConversationState);
  const uid = localStorage.getItem(LocalStorageKey.UID);
  const chat_username = localStorage.getItem(LocalStorageKey.CHAT_USERNAME);
  const location: any = useLocation();
  const firstTimeCreateUser = location?.state?.firstTimeCreateUser;
  const defaultFilter: IFilter = {
    page_index: 1,
    page_size: 10,
    sender_uid: access_token ? undefined : uid,
  };
  const {
    filter: conversationFilter,
    handleFilterChange,
    handleSearch: handleConversationSearch,
  } = useFilter(defaultFilter);
  const [senderInfo, setSenderInfo] = useState<any>();

  const loadMoreData = () => {
    handleFilterChange({
      ...conversationFilter,
      page_index: (conversationFilter.page_index || 0) + 1,
    });
  };

  const getSenderInfo = async () => {
    try {
      const params = {
        uid,
      };
      const resultSenderInfo = await getSenderInfoAPI(params);
      setSenderInfo(resultSenderInfo?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReceivedListConversations = (listConversationsResult: any) => {
    let conversationsListAfterSeenAll = [];
    const itemInList = conversationState.listConversationAll?.find(
      (conItem) => conItem?.id === listConversationsResult?.id
    );
    // If itemSocket is not in list + TRUE conversation uid
    if (!itemInList && listConversationsResult?.receiver?.uid === senderInfo?.uid) {
      conversationsListAfterSeenAll = [
        {
          id: listConversationsResult?.id,
          last_message: listConversationsResult?.last_message,
          partner: listConversationsResult?.sender,
        },
        ...conversationState.listConversationAll,
      ];
    }
    // If itemSocket is not in list + FALSE conversation uid
    else if (!itemInList && listConversationsResult?.receiver?.uid !== senderInfo?.uid) {
      conversationsListAfterSeenAll = [...conversationState.listConversationAll];
    }
    // If itemSocket is in list
    else {
      conversationsListAfterSeenAll = [
        {
          id: itemInList?.id,
          last_message: listConversationsResult?.last_message,
          partner: listConversationsResult?.sender,
        },
        ...conversationState.listConversationAll.filter((conItem) => conItem?.id !== listConversationsResult?.id),
      ];
    }
    setConversationState({
      ...conversationState,
      listConversationAll: conversationsListAfterSeenAll,
    });
  };

  const { data: listConversation, isFetching: fetchingListConversation }: any = useQuery(
    ["listConversation", conversationFilter],
    () => getListConversationsAPI(conversationFilter),
    { cacheTime: 0 }
  );

  useEffect(() => {
    if (listConversation?.data && !fetchingListConversation) {
      setConversationState({
        ...conversationState,
        listConversationAll: [...conversationState.listConversationAll, ...listConversation?.data],
        hasMore: listConversation?.data?.length === conversationFilter?.page_size,
      });
      setIsCustomLoading(false);
    }
  }, [fetchingListConversation]);

  useEffect(() => {
    if (!access_token && !chat_username) {
      history.push("/chat-fill-name");
    }
  }, [chat_username, access_token]);

  useEffect(() => {
    getSenderInfo();
  }, []);

  useSocket({
    handleReceivedListConversations,
  });

  return (
    senderInfo && (
      <div className={styles.container}>
        <SocketMesModal />
        <PageHeader
          iconLeft={ImagesObj.BackIcon}
          onPressLeft={() => {
            if (firstTimeCreateUser) {
              history.replace("/");
              return;
            }
            // if (access_token || (!access_token && (chat_username || uid))) {
            //   history.replace("/");
            //   return;
            // }
            history.back();
          }}
          title={t("chat.title")}
          iconRight={!access_token ? ImagesObj.PencilIconBlack : undefined}
          onPressRight={() => {
            if (!access_token) {
              history.push("/chat-new");
            }
          }}
        />
        <div className={styles.conversationSearch}>
          <Input
            placeholder={t("chat.placeHolderKeyword")}
            onChange={(e) => {
              setIsCustomLoading(true);
              setConversationState({
                ...conversationState,
                listConversationAll: [],
                hasMore: false,
              });
              handleConversationSearch.keywordSearch(e);
            }}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className={styles.recipientsContainer}>
          <InfiniteScroll
            dataLength={conversationState.listConversationAll.length || 0}
            next={loadMoreData}
            hasMore={conversationState.hasMore}
            loader={
              <Spin className={styles.spinLoader} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
            }
          >
            <List
              className={classNames(styles.recipientsList, {
                [styles[`recipientsList--noData`]]: conversationState.listConversationAll?.length === 0,
              })}
              loading={fetchingListConversation || isCustomLoading}
              dataSource={conversationState.listConversationAll || []}
              locale={{ emptyText: <Empty description={t("common.emptyText")} /> }}
              renderItem={(item: IConversation) => {
                const senderName = item?.last_message?.sender?.id === senderInfo?.id ? `${t("chat.yourself")}: ` : "";
                let senderContent = item?.last_message?.content;
                const isThisMessageFormattedSeen =
                  !!item?.last_message?.seen || item?.last_message?.sender?.id === senderInfo?.id;
                if (
                  !item?.last_message?.content &&
                  item?.last_message?.attachments &&
                  item?.last_message?.attachments?.length > 0
                ) {
                  senderContent = t("chat.imageVideo");
                }
                return (
                  <List.Item
                    onClick={() => {
                      history.push(`/chat-details?conversation-id=${item?.id}`, {
                        chosenRecipients: [item],
                      });
                    }}
                    className={styles.recipientsListItem}
                    key={item.id}
                  >
                    <Space direction="horizontal" size="large">
                      <Avatar
                        avatar={item?.partner?.avatar?.url}
                        avatarThumb={item?.partner?.avatar?.thumb?.url}
                        needBehindIcon={false}
                      />
                      <Space direction="vertical">
                        <div
                          className={classNames(styles.txtSenderName, {
                            [styles[`txtSenderName--unseen`]]: !isThisMessageFormattedSeen,
                          })}
                        >
                          {item?.partner?.name}
                        </div>
                        <Space direction="horizontal" size="small">
                          <div
                            className={classNames(styles.txtContent, {
                              [styles[`txtContent--unseen`]]: !isThisMessageFormattedSeen,
                            })}
                          >
                            {senderName}
                            {senderContent}
                          </div>
                          <div
                            className={classNames(styles.txtContent, {
                              [styles[`txtContent--unseen`]]: !isThisMessageFormattedSeen,
                            })}
                          >
                            ・
                            {convertTimeToAgo({
                              timestamp: item?.last_message?.created_at,
                              translation: t,
                            })}
                          </div>
                        </Space>
                      </Space>
                    </Space>
                    {!isThisMessageFormattedSeen && <div className={styles.btnCircleStatus} />}
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
        </div>
      </div>
    )
  );
}
