import { sendGet, sendPost } from "./axios";

export const startLiveStream = (payload: any) => sendPost("/stream/streamers/start-streaming", payload);
export const joinLiveStream = (id: number, payload: any) =>
  sendPost(`/stream/streamers/${id}/join-live-stream`, payload);
export const leaveLiveStream = (id: number, payload: any) =>
  sendPost(`/stream/streamers/${id}/leave-live-stream`, payload);
export const stopStreaming = () => sendPost("/stream/streamers/stop-streaming");
export const getListHashtags = () => sendGet("/stream/hashtags");
export const getIdolInfo = (id: number) => sendGet(`/stream/streamers/${id}`);
export const getStreamingHistories = (id: number, params: any) =>
  sendGet(`/stream/streamers/${id}/streaming-histories`, params);
export const getStreamers = (params: any) => sendGet("/stream/streamers", { ...params, is_streaming: false });
export const getDetailStreamingHistory = (id: number) => sendGet(`/stream/streaming_histories/${id}`);
export const getStreamersStreaming = (params: any) => sendGet("/stream/streamers", { ...params, is_streaming: true });
export const getStreamAudiences = (id: number) => sendGet(`/stream/streamers/${id}/audiences`);
export const getStreamingHistoryWithHashtags = (params: any) => sendGet(`/stream/streaming_histories`, params);
export const getStreamingStatus = () => sendGet("/stream/streamers/status");
export const postHideAudio = (id?: any) => sendPost(`/stream/streaming_histories/${id}/hidden`);
