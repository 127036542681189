import styles from "./styles.module.scss";

interface IProps {
  onClick(): void;
  title?: string;
  image?: string;
  imageWidth?: number | string;
  imageHeight?: number | string;
}

export default function FooterItem({ onClick, title, image, imageWidth, imageHeight }: IProps) {
  return (
    <div>
      <button className={styles.footerTxtWrapper} onClick={onClick}>
        {!!title && <div className={styles.footerTxt}>{title}</div>}
        {!!image && !!imageWidth && !!imageHeight && (
          <img width={imageWidth} height={imageHeight} src={image} alt="Icon Footer" />
        )}
      </button>
    </div>
  );
}
