import { useEffect } from "react";
import { socket } from "utils/socket";

interface IProps {
  conversationId?: number;
  handleSeenMessage?(socketResult: any): void;
  handleReceivedMessage?(socketResult: any): void;
  handleReceivedListConversations?(socketResult: any): void;
}

function useSocket({
  handleSeenMessage,
  handleReceivedMessage,
  handleReceivedListConversations,
  conversationId,
}: IProps) {
  const createRoomChatSubscription = () => {
    socket.actionCable?.subscriptions.create(
      { channel: "MessageChannel", conversation_id: conversationId },
      { received: handleSeenMessage }
    );
  };
  const createMessageSubscription = () => {
    socket.actionCable?.subscriptions.create(
      { channel: "RoomChatChannel", conversation_id: conversationId },
      { received: handleReceivedMessage }
    );
  };
  const createListConversationsSubscription = () => {
    socket.actionCable?.subscriptions.create(
      { channel: "ListConversationChannel", conversation_id: conversationId },
      { received: handleReceivedListConversations }
    );
  };

  useEffect(() => {
    createRoomChatSubscription();
    createMessageSubscription();
    createListConversationsSubscription();
  });

  return {};
}

export default useSocket;
