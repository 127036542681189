import Cookies from "js-cookie";

export const USER_TOKEN = "access_token";
export const USER_REFRESH_TOKEN = "refresh_token";

export const EXPIRES = 0;

const setItem = (key: string, value: any, expireHours?: number) => {
  let expireDate;
  if (typeof expireHours === "number") {
    if (expireHours !== 0) {
      expireDate = new Date(Date.now() + expireHours * 60 * 60 * 1000);
    } else {
      expireDate = undefined;
    }
  } else {
    expireDate = EXPIRES;
  }

  Cookies.set(key, value, {
    expires: expireDate,
    path: "/",
  });
};

const getItem = (key: string) => {
  return JSON.parse(Cookies.get(key) || "null");
};

const removeItem = (key: string) => {
  Cookies.remove(key, { path: "/" });
};

const setToken = (value: any) => {
  setItem(USER_TOKEN, value);
};

const clearToken = () => {
  removeItem(USER_TOKEN);
};

const getToken = () => {
  return getItem(USER_TOKEN);
};

const getRefreshToken = () => getItem(USER_REFRESH_TOKEN);

const storage = {
  setItem,
  getItem,
  removeItem,
  setToken,
  clearToken,
  getToken,
  getRefreshToken,
};
export default storage;
