import ImagesObj from "assets/images";
import configs from "constants/config";
import { SNSType } from "constants/enum";
import { IArrayItem, IConversation, IMessage, initialFormItem, IReceiver, IStreamer, ITag } from "constants/interface";
import { LISTENERS_NUMBERS } from "constants/staticData";
import moment from "moment";
import { TFunction } from "react-i18next";
import storage from "./storage";

export const convertStringToNumber = (str?: string | null) => {
  return parseInt(str || "0") || 0;
};

export const splitHashtags = (text?: string | null) => {
  const wordsArr = text
    ? text
        .split(" ")
        ?.map((wAAS) => wAAS.split("　")?.map((wAAJS) => wAAJS?.split("#")))
        ?.flat(3)
        ?.filter((wA) => wA !== "")
    : [];
  return wordsArr;
};

export const sortStreamers = (streamers: IStreamer[]) => {
  return streamers.sort((item1: IStreamer, item2: IStreamer) => {
    return item2.status.length - item1.status.length;
  });
};

export const checkAccountSNS = (accountSNS: string, type: SNSType) => {
  const fullPaths = [];
  console.log(configs.CHERRY_LIVE_URL, configs.CHERRY_LIVE_SUB_URL);
  const paths = [];
  if (type === SNSType.YOUTUBE) {
    fullPaths.push("https://youtube.com/", "https://www.youtube.com/");
    paths.push("youtube.com/", "www.youtube.com/");
  } else if (type === SNSType.TWITTER) {
    fullPaths.push("https://twitter.com/");
    paths.push("twitter.com/");
  } else if (type === SNSType.INSTAGRAM) {
    fullPaths.push("https://instagram.com/", "https://www.instagram.com/");
    paths.push("instagram.com/", "www.instagram.com/");
  } else {
    fullPaths.push(configs.CHERRY_LIVE_URL);
    paths.push(configs.CHERRY_LIVE_SUB_URL);
  }
  for (const fullPath of fullPaths) {
    if (accountSNS.includes(fullPath)) {
      return accountSNS;
    }
  }
  for (const path of paths) {
    if (accountSNS.includes(path)) {
      return "https://" + accountSNS;
    }
  }
  return fullPaths[0] + accountSNS;
};

export const convertDateJP = (date?: string, hasTime = false) => {
  if (!date) {
    return "";
  }
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return "";
  }
  return `${momentDate.year()}年${momentDate.month() + 1}月${momentDate.date()}日${
    hasTime ? ` ${momentDate.hour()}時${momentDate.minute()}分` : ""
  }`;
};

export const resetFormFields = ({ form, profile }: any) => {
  return {
    ...form,
    username: { ...initialFormItem, firstTimeRender: !profile?.name, value: String(profile?.name || "") },
    twitter: {
      ...initialFormItem,
      firstTimeRender: !profile?.twitter_account,
      value: String(profile?.twitter_account || ""),
    },
    instagram: {
      ...initialFormItem,
      firstTimeRender: !profile?.instagram_account,
      value: String(profile?.instagram_account || ""),
    },
    youtube: {
      ...initialFormItem,
      firstTimeRender: !profile?.youtube_account,
      value: String(profile?.youtube_account || ""),
    },
    cherryLive: {
      ...initialFormItem,
      firstTimeRender: !profile?.cherry_live_link,
      value: String(profile?.cherry_live_link || ""),
    },
    numOfListeners: {
      ...initialFormItem,
      firstTimeRender: false,
      value: Number(profile?.audience_limit || LISTENERS_NUMBERS.max),
    },
    avaUrl: { ...initialFormItem, firstTimeRender: false, value: getAvatar(profile?.avatar?.url, profile?.id) },
    avaThumbUrl: {
      ...initialFormItem,
      firstTimeRender: false,
      value: getAvatar(profile?.avatar?.thumb?.url, profile?.id),
    },
    backgroundUrl: {
      ...initialFormItem,
      firstTimeRender: false,
      value: getBackground(profile?.background?.url, profile?.id),
    },
    backgroundThumbUrl: {
      ...initialFormItem,
      firstTimeRender: false,
      value: getBackground(profile?.background?.thumb?.url, profile?.id),
    },
  };
};

export const getAvatar = (avatar?: string | null, id?: string) => {
  if (avatar) {
    const dataUpdate = storage.getItem("updateAvatarTime");
    if (convertStringToNumber(id) === dataUpdate?.id) {
      return `${avatar}?${dataUpdate?.time}`;
    }
  }
  if (avatar === null) return ImagesObj.UserIconDefault;
  return avatar;
};

export const getBackground = (background?: string | null, id?: string) => {
  if (background) {
    const dataUpdate = storage.getItem("updateBackgroundTime");
    if (convertStringToNumber(id) === dataUpdate?.id) {
      return `${background}?${dataUpdate?.time}`;
    }
  }
  if (background === null) return `${null}`;
  return background;
};

export const checkHashtag = (keyword: string, hashtags: ITag[]) => {
  for (const h of hashtags) {
    if (h?.name?.includes(keyword)) {
      return true;
    }
  }
  return false;
};

export const checkKeywordIdol = (keywords: string[], streamer: IStreamer) => {
  if (keywords.length === 0) return true;
  for (const k of keywords) {
    if (streamer?.profile?.name.includes(k) || checkHashtag(k, streamer.hashtags)) {
      return true;
    }
  }

  return false;
};

export const checkItem = (array: Array<IArrayItem>, id: number) => {
  return array.some((item: IArrayItem) => item.id === id);
};

export const convertTimeToAgo = ({ timestamp, translation }: { timestamp?: string; translation?: TFunction }) => {
  const now = moment();
  const momentTime = moment(timestamp);
  const rangeArr = [
    {
      value: now.diff(momentTime, "seconds"),
      max: 59,
      suffixText: translation ? translation("durationAgo.now") : "",
    },
    {
      value: now.diff(momentTime, "minutes"),
      max: 59,
      suffixText: translation ? translation("durationAgo.minutes") : "",
    },
    {
      value: now.diff(momentTime, "hours"),
      max: 59,
      suffixText: translation ? translation("durationAgo.hours") : "",
    },
    {
      value: now.diff(momentTime, "days"),
      max: 6,
      suffixText: translation ? translation("durationAgo.days") : "",
    },
    {
      value: now.diff(momentTime, "weeks"),
      max: 3,
      suffixText: translation ? translation("durationAgo.weeks") : "",
    },
    {
      value: now.diff(momentTime, "months"),
      max: 11,
      suffixText: translation ? translation("durationAgo.months") : "",
    },
    {
      value: now.diff(momentTime, "years"),
      max: Infinity,
      suffixText: translation ? translation("durationAgo.years") : "",
    },
  ];
  for (let item of rangeArr) {
    if (item?.value <= item?.max) {
      return rangeArr.indexOf(item) === 0 ? item?.suffixText : `${item?.value} ${item?.suffixText}`;
    }
  }
};

export const convertTimeHoursDate = ({ timestamp, translation }: { timestamp?: string; translation?: TFunction }) => {
  const momentTime = moment(timestamp);
  return momentTime.format("HH:mm, DD/MM/YYYY");
};

export const processGroupMessages = ({
  curMessages,
  newMessages,
}: {
  curMessages: Array<IMessage>;
  newMessages: Array<IMessage>;
}) => {
  let resultArr = [];
  const newMessageArrReversed = [...curMessages, ...newMessages].reverse();
  // If array length === 1, no need to loop
  if (newMessageArrReversed?.length === 1) {
    resultArr = [
      {
        ...newMessageArrReversed[0],
        isShowTime: true,
      },
    ];
  } else {
    const resultArrReversed = [];
    for (let newMessageIndex = 0; newMessageIndex < newMessageArrReversed?.length; newMessageIndex++) {
      let nextPivotMessageIndex = newMessageIndex + 1;
      resultArrReversed.push({
        ...newMessageArrReversed[newMessageIndex],
        isShowTime: true,
      });
      // When nextPivotItem is defined and diff between currentItem and nextItem are lower than 4 minutes
      // Do loop
      while (
        !!newMessageArrReversed[nextPivotMessageIndex]?.created_at &&
        moment(newMessageArrReversed[nextPivotMessageIndex]?.created_at).diff(
          moment(newMessageArrReversed[newMessageIndex]?.created_at),
          "minutes"
        ) < 4
      ) {
        resultArrReversed.push({
          ...newMessageArrReversed[nextPivotMessageIndex],
          isShowTime: undefined,
        });
        newMessageIndex = nextPivotMessageIndex;
        nextPivotMessageIndex = nextPivotMessageIndex + 1;
      }
    }
    resultArr = resultArrReversed.reverse();
  }
  return resultArr;
};

export const check30s = (createdAt: string) => {
  if (!createdAt) {
    return false;
  }
  const createAtTime = new Date(createdAt).getTime();
  const now = new Date().getTime();
  return Math.floor((now - createAtTime) / 1000) < 30;
};
