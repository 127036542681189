import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { history } from "App";

import Button from "components/Button";
import storage from "utils/helper/storage";
import { getStreamingStatus } from "api/liveStream";
import { StatusStreamer } from "constants/enum";

export default function ButtonCreateLive() {
  const [t] = useTranslation();

  const isAuthenticated = !!Cookies.get("access_token");

  const checkLiveStream = async () => {
    const infoIdolLive = storage.getItem("infoIdolLive")?.data;
    if (isAuthenticated) {
      const streamingStatus = await getStreamingStatus();
      if (infoIdolLive && streamingStatus?.data?.status === StatusStreamer.STREAMING) {
        history.push("/live-stream-idol");
      } else {
        storage.removeItem("infoIdolLive");
        history.push("/pre-live-stream");
      }
    } else {
      history.push("/login");
    }
  };

  return <Button title={isAuthenticated ? t("btn.createLive") : t("btn.login")} onClick={checkLiveStream} />;
}
