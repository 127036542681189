import { Modal as ModalAntd } from "antd";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import { ButtonType } from "constants/enum";
import classNames from "classnames";

interface IProps {
  isVisibleModal: boolean;
  setIsVisibleModal?: Function;
  children: JSX.Element;
  className?: string;
  isTwoOption?: boolean;
  okText?: string;
  cancelText?: string;
  onOk?: Function;
  onCancel?: Function;
  loading?: boolean;
}
interface IFotterProps {
  onClose: Function;
  onOk?: Function;
  onCancel?: Function;
  isTwoOption?: boolean;
  okText?: string;
  loading?: boolean;
  cancelText?: string;
}

const ModalFooter = ({ onClose, isTwoOption, okText, onOk, loading, cancelText }: IFotterProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button title={cancelText || t("btn.close")} type={ButtonType.NONE} onClick={() => onClose()} />
      {isTwoOption && (
        <Button
          title={okText}
          type={ButtonType.NONE}
          onClick={() => {
            onOk && onOk();
          }}
          disabled={!!loading}
        />
      )}
    </div>
  );
};

export default function Modal({
  isVisibleModal,
  setIsVisibleModal,
  children,
  className,
  isTwoOption,
  okText,
  onOk,
  loading,
  onCancel,
  cancelText,
}: IProps) {
  return (
    <ModalAntd
      title={null}
      visible={isVisibleModal}
      onCancel={() => {
        if (setIsVisibleModal) {
          setIsVisibleModal(false);
        } else if (onCancel) {
          onCancel();
        }
      }}
      footer={
        <ModalFooter
          onClose={() => {
            if (setIsVisibleModal) {
              setIsVisibleModal(false);
            } else if (onCancel) {
              onCancel();
            }
          }}
          isTwoOption={isTwoOption}
          okText={okText}
          onOk={onOk}
          loading={loading}
          onCancel={onCancel}
          cancelText={cancelText}
        />
      }
      closable={false}
      className={classNames("modal", className)}
      maskClosable={false}
    >
      {children}
    </ModalAntd>
  );
}
