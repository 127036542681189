import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import RootWrapper from "wrappers/RootWrapper";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ScrollToTop from "components/ScrollToTop";

export const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history}>
        <React.Suspense fallback={<Spin className="spin-loading-screen" size="large" />}>
          <ScrollToTop />
          <RootWrapper />
        </React.Suspense>
      </HistoryRouter>
    </QueryClientProvider>
  );
}

export default App;
