import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { IFilter } from "constants/interface";
import { DebounceTime } from "constants/staticData";

export default function useFilter(defaultFilter: IFilter) {
  const [filter, setFilter] = useState<IFilter>(defaultFilter);

  const debounceKeyword = useDebouncedCallback((keyword) => {
    handleFilterChange({ keyword: keyword ? keyword?.trim() : undefined, page_index: 1 });
  }, DebounceTime.DEFAULT);

  const handleFilterChange = (changeValue: IFilter) => {
    setFilter({ ...filter, ...changeValue });
  };

  const keywordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceKeyword(e.target.value);
  };

  return {
    filter,
    setFilter,
    handleFilterChange,
    handleSearch: {
      keywordSearch,
    },
  };
}
