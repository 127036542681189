import { REGEX_CONTAIN_SPECIAL_CHARS, REGEX_EMAIL, REGEX_EMOJI, REGEX_PASSWORD } from "./regex";

const checkIsBlank = (value?: string | number) => {
  return !!(!value || value.toString().trim() === "");
};

const checkHasEmoji = (value?: string | number) => {
  return !!value?.toString().match(REGEX_EMOJI);
};

const checkHasSpaceStartEndIndex = (value?: string | number) => {
  return !!(value?.toString()[0] === " " || value?.toString()[value?.toString().length - 1] === " ");
};

const checkHasSpecialHTMLChars = (value?: string | number) => {
  return value?.toString().match(REGEX_CONTAIN_SPECIAL_CHARS);
};

const validateUsernameLogin = ({ value }: { value?: string | number }) => {
  if (checkIsBlank(value)) {
    return { error: "ユーザー名を入力してください。" };
  }
  if (checkHasEmoji(value) || checkHasSpecialHTMLChars(value)) {
    return { error: "ユーザー名の形式が不正です。" };
  }
  return { error: "" };
};

const validateEmailLogin = ({ value }: { value?: string | number }) => {
  if (checkIsBlank(value)) {
    return { error: "メールアドレスを入力してください。" };
  }
  if (checkHasEmoji(value) || !value?.toString().match(REGEX_EMAIL)) {
    return { error: "メールアドレスの形式が不正です。" };
  }
  return { error: "" };
};

const validatePasswordLogin = ({ value }: { value?: string | number }) => {
  if (checkIsBlank(value)) {
    return { error: "パスワードを入力してください。" };
  }
  if (checkHasEmoji(value)) {
    return { error: "パスワードの形式が不正です。" };
  }
  return { error: "" };
};

const Validation = {
  validateUsernameLogin,
  validatePasswordLogin,
  validateEmailLogin,
};
export default Validation;
