import { useState } from "react";
import ImagesObj from "assets/images";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IProps {
  avatar?: string;
  avatarThumb?: string;
  needBehindIcon?: boolean;
}

export default function Avatar({ avatar, avatarThumb, needBehindIcon = true }: IProps) {
  const [isLoadFullImage, setIsLoadFullImage] = useState(false);

  return (
    <div
      className={classNames(styles.avatarInfo, {
        [styles[`avatarInfo--noBehindIcon`]]: !needBehindIcon,
      })}
    >
      {
        <img
          src={avatarThumb || ImagesObj.UserIconDefault}
          alt="AvatarThumb"
          className={classNames(
            styles.avatar,
            { [styles[`avatar--noBehindIcon`]]: !needBehindIcon },
            { "d-none": isLoadFullImage }
          )}
        />
      }
      {
        <img
          src={avatar || ImagesObj.UserIconDefault}
          alt="Avatar"
          className={classNames(
            styles.avatar,
            { [styles[`avatar--noBehindIcon`]]: !needBehindIcon },
            { "d-none": !isLoadFullImage }
          )}
          onLoad={() => {
            setIsLoadFullImage(true);
          }}
        />
      }
      {needBehindIcon && (
        <img src={ImagesObj.AvatarPlace} alt="Avatar Place" style={{ height: 55, marginBottom: -10 }} />
      )}
    </div>
  );
}
