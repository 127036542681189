import ImagesObj from "assets/images";
import { genStreamersData, genStreamData, genListTag } from "utils/helper/generateFakeData";
import { ChatItemDirectionType, ChatItemContentType } from "./enum";

export const StreamersFakeData = genStreamersData({ numOfStreamers: 20 });

export const FAQFakeData = [
  {
    question: "どんなサービスなの？",
    answer:
      "roomyでは声だけでライブ配信をするサービスです<br />ライブ配信を無料でできます<br />ゆったりでは、声さえあれば<br />誰でもオーディオクリエイターになります",
    image: ImagesObj.FAQImage1,
  },
  {
    question: "姉妹サービスへの連携も",
    answer:
      "roomyでは配信者のプロフィール画面や<br />配信中の画面から、姉妹サービスの<br />Cherry LiveへLIVE配信を観に行く事ができます",
    image: ImagesObj.FAQImage2,
  },
];

export const TermsFakeData = [
  {
    key: "roomy利用規約",
    value:
      "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
  },
  {
    key: "プライバシーポリシー",
    value:
      "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
  },
  {
    key: "特定商取引法",
    value:
      "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
  },
];

export const preStreamFakeData = genStreamData();

export const listTagFakeData = genListTag();

export const LIMIT_FILE_SIZE = 5e6;

export const LIMIT_VIDEO_FILE_SIZE = 30e6;

export const acceptedImageType = ["image/png", "image/jpeg", "image/jpg"];

export const acceptedVideoType = ["video/mp4"];

export const LISTENERS_NUMBERS = {
  min: 50,
  max: 3000,
};

export const fakeChatList = [
  {
    content: "Hỗn độn sinh nhất, nhất sinh nhị, nhị sinh tam, tam sinh vạn vật, vạn vật diễn hóa chư thiên.",
    username: "hangdodo",
    direction: ChatItemDirectionType.RECEIVED,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content:
      "Thiên địa bất nhân, dĩ vạn vật vi sô cẩu. Một kiếm chém ngang thiên địa, trấn áp vạn cổ chư thiên. Không tin luân hồi, chỉ tin đương thời.",
    username: "zhang_uk",
    direction: ChatItemDirectionType.SENT,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content: "https://thuvienanime.com/wp-content/uploads/2021/09/bach-tieu-thuan.jpeg",
    username: "hangdodo",
    direction: ChatItemDirectionType.RECEIVED,
    contentType: ChatItemContentType.IMAGE,
  },
  {
    content: "https://thuvienanime.com/wp-content/uploads/2021/09/bach-tieu-thuan.jpeg",
    username: "hangdodo",
    direction: ChatItemDirectionType.RECEIVED,
    contentType: ChatItemContentType.IMAGE,
  },
  {
    content:
      "Thiên địa bất nhân, dĩ vạn vật vi sô cẩu. Một kiếm chém ngang thiên địa, trấn áp vạn cổ chư thiên. Không tin luân hồi, chỉ tin đương thời.",
    username: "zhang_uk",
    direction: ChatItemDirectionType.SENT,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content:
      "Thiên địa bất nhân, dĩ vạn vật vi sô cẩu. Một kiếm chém ngang thiên địa, trấn áp vạn cổ chư thiên. Không tin luân hồi, chỉ tin đương thời.",
    username: "zhang_uk",
    direction: ChatItemDirectionType.SENT,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content: "Hello",
    username: "zhang_uk",
    direction: ChatItemDirectionType.SENT,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content: "Hello",
    username: "hangdodo",
    direction: ChatItemDirectionType.RECEIVED,
    contentType: ChatItemContentType.TEXT,
  },
  {
    content: "Hello darkness my old friend",
    username: "hangdodo",
    direction: ChatItemDirectionType.RECEIVED,
    contentType: ChatItemContentType.TEXT,
  },
];

export const DebounceTime = {
  DEFAULT: 200,
};
