import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import vi from "./locales/vi.json";
// import en from "./locales/en.json";
import ja from "./locales/jp.json";
import { getLanguages } from "api/language";
import { EnvType, LanguageType } from "constants/enum";
import { handleErrorMessage } from "i18n";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ja",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      // vi: { translation: vi },
      // en: { translation: en },
      ja: { translation: ja },
    },
  });

export const setResourceI18n = async () => {
  try {
    const dataJa = await getLanguages({
      environment: EnvType.APP,
      code: LanguageType.JA,
    });
    i18n.addResourceBundle("ja", "translation", dataJa, true, true);
  } catch (error) {
    handleErrorMessage(error);
  }
};
