import AgoraRTC, { IAgoraRTCClient, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";

class AgoraIdolManager {
  private client?: IAgoraRTCClient;
  private audioTrack?: IMicrophoneAudioTrack;

  async getClient() {
    if (this.client) return this.client;
    const client = AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
    });
    this.client = client;
    await client.setClientRole("host");
    // client.on("token-privilege-will-expire", async () => {
    //   try {
    //     // const {
    //     //   data: { token },
    //     // } = await createLiveStreamToken();
    //     // client.renewToken(token);
    //   } catch {}
    // });
    client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await client.subscribe(user, mediaType);
      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack?.play();
      }
    });
    return client;
  }
  async getAudioTrack() {
    if (this.audioTrack) return this.audioTrack;
    const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.audioTrack = audioTrack;
    return audioTrack;
  }
  async stop() {
    this.client?.leave();
    this.client = undefined;
    this.audioTrack?.close();
    this.audioTrack = undefined;
  }
}

const agoraIdolManager = new AgoraIdolManager();
export default agoraIdolManager;
