import { useMutation } from "react-query";
import { useState } from "react";

import { leaveLiveStream, stopStreaming } from "api/liveStream";
import storage from "utils/helper/storage";
import agoraUserManager from "utils/agora/agoraUserManager";
import agoraIdolManager from "utils/agora/agoraIdolManager";
import { SessionStorageKey } from "constants/enum";

interface IStateCheck {
  isCheckAll: boolean;
}

export default function useCheckStopLive() {
  const infoIdolLive = storage.getItem("infoIdolLive")?.data;
  const infoUserLive = JSON.parse(sessionStorage.getItem("infoUserLive") || "null")?.data;
  const [stateCheck, setStateCheck] = useState<IStateCheck>({
    isCheckAll: false,
  });

  const { mutate: postLeaveLive, isLoading: loadingLeaveLive } = useMutation(
    (params: any) =>
      leaveLiveStream(params?.id, {
        uid: params?.uid,
      }),
    {
      onSuccess: () => {
        sessionStorage.removeItem("infoUserLive");
        setStateCheck({ ...stateCheck, isCheckAll: true });
      },
    }
  );
  const { mutate: postStopLive, isLoading: loadingStopLive } = useMutation(() => stopStreaming(), {
    onSuccess: () => {
      storage.removeItem("infoIdolLive");
      setStateCheck({ ...stateCheck, isCheckAll: true });
    },
  });

  const muteStopLive = () => {
    const isInLive = !!sessionStorage.getItem(SessionStorageKey.IS_IN_LIVE);
    if (infoIdolLive && !loadingStopLive && isInLive) {
      agoraIdolManager.stop();
      postStopLive();
    } else if (infoUserLive && !loadingLeaveLive) {
      agoraUserManager.stop(infoUserLive?.streamer_id);
      postLeaveLive({ id: +infoUserLive?.streamer_id, uid: infoUserLive?.uid });
    } else {
      setStateCheck({ ...stateCheck, isCheckAll: true });
    }
  };

  return { muteStopLive, isCompleteCheck: stateCheck.isCheckAll };
}
