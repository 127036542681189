import AgoraRTC, { IAgoraRTCClient, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";

AgoraRTC.onAutoplayFailed = () => {
  const muteIcon = document.getElementById("muteIcon");
  muteIcon?.classList.remove("d-none");
  document.body.addEventListener("click", () => {
    if (!muteIcon?.className.split(" ").includes("d-none")) {
      muteIcon?.classList.add("d-none");
    }
  });
};

class AgoraUserManager {
  private clients: {
    [idolId: number]: IAgoraRTCClient;
  } = {};
  private audioTracks: {
    [idolId: number]: IMicrophoneAudioTrack;
  } = {};

  async getClient(idolId: number) {
    if (this.clients[idolId]) return this.clients[idolId];
    const client = AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
    });
    this.clients[idolId] = client;
    await client.setClientRole("audience");

    // client.on("token-privilege-will-expire", async () => {
    //   try {
    //     // const {
    //     //   data: { token },
    //     // } = await createLiveStreamToken(idolId);
    //     // client.renewToken(token);
    //   } catch {}
    // });
    client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await client.subscribe(user, mediaType);
      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack?.play();
      }
    });
    return client;
  }
  async getAudioTrack(idolId: number) {
    if (this.audioTracks[idolId]) return this.audioTracks[idolId];
    const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.audioTracks[idolId] = audioTrack;
    return audioTrack;
  }
  async stop(idolId: number) {
    this.clients[idolId]?.leave();
    delete this.clients[idolId];
    this.audioTracks[idolId]?.close();
    delete this.audioTracks[idolId];
  }
}
const agoraUserManager = new AgoraUserManager();
export default agoraUserManager;
