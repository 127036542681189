import ImagesObj from "assets/images";
import { LISTENERS_NUMBERS } from "constants/staticData";

export const genStreamersData = ({ numOfStreamers }: { numOfStreamers: number }) => {
  let currentNumOfStreamers = numOfStreamers === undefined ? 10 : numOfStreamers;
  const imageURLArr = [
    ImagesObj.AvatarDefault,
    ImagesObj.AvatarDefault2,
    ImagesObj.AvatarDefault3,
    ImagesObj.AvatarDefault4,
  ];
  const isActiveArr = [false, true];
  const nameArr = ["Naoki", "Kayo", "Miu", "Katy"];
  const hashTagsArr = ["Facebook", "Firebase", "Yahoo", "Google", "Gmail", "Skype", "Zalo"];

  let resultArr = [];
  for (let index = 0; index < currentNumOfStreamers; index++) {
    const imageURLIndexRand = Math.floor(Math.random() * imageURLArr.length);
    const isActiveIndexRand = Math.floor(Math.random() * isActiveArr.length);
    const nameIndexRand = Math.floor(Math.random() * nameArr.length);
    const hashTagsIndexRand = Math.floor(Math.random() * hashTagsArr.length);
    resultArr.push({
      imageURL: imageURLArr[imageURLIndexRand],
      isActive: isActiveArr[isActiveIndexRand],
      name: nameArr[nameIndexRand],
      hashTags: hashTagsArr.filter((_, index) => index <= hashTagsIndexRand),
      numOfListeners: Math.floor(Math.random() * 1000),
    });
  }
  return resultArr;
};

export const genNumOfListeners = () => {
  const initArr = new Array(LISTENERS_NUMBERS.max).fill("");
  const resultArr = initArr
    .filter((_, initIndex) => (initIndex + 1) % LISTENERS_NUMBERS.min === 0)
    .map((_, initIndex) => (initIndex + 1) * LISTENERS_NUMBERS.min);
  return resultArr;
};

export const genStreamData = (numOfStreams: number = 10) => {
  const hashTagsArr = ["Facebook", "Firebase", "Yahoo", "Google", "Gmail", "Skype", "Zalo"];
  const nameArr = ["Naoki", "Kayo", "Miu", "Katy"];
  let resultArr = [];
  for (let i = 0; i < numOfStreams; i++) {
    const background = ImagesObj.BackgroundLiveDefault;
    const hashTagsIndexRand = Math.floor(Math.random() * hashTagsArr.length);
    const nameIndexRand = Math.floor(Math.random() * nameArr.length);
    resultArr.push({
      background: background,
      author: nameArr[nameIndexRand],
      tags: hashTagsArr.filter((_, index) => index <= hashTagsIndexRand),
      numOfListeners: Math.floor(Math.random() * 1000),
    });
  }
  return resultArr;
};

export const genListTag = (numOfTags: number = 5) => {
  let resultArr = [];
  const hashTagsArr = ["Facebook", "Firebase", "Yahoo", "Google", "Gmail", "Skype", "Zalo"];
  for (let i = 0; i < numOfTags; i++) {
    const hashTagsIndexRand = Math.floor(Math.random() * hashTagsArr.length);
    resultArr.push({
      id: i,
      title: hashTagsArr[hashTagsIndexRand],
    });
  }
  return resultArr;
};
