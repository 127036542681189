import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IStreamer } from "constants/interface";
import { SocketStatus, StatusStreamer } from "constants/enum";
import agoraIdolManager from "utils/agora/agoraIdolManager";
import storage from "utils/helper/storage";
import Modal from "components/Modal";
import { socket } from "utils/socket";
import { logout } from "utils/helper/authentication";
import agoraUserManager from "utils/agora/agoraUserManager";

export default function SocketMesModal() {
  const infoIdolLive = storage.getItem("infoIdolLive")?.data;
  const infoUserLive = JSON.parse(sessionStorage.getItem("infoUserLive") || "null")?.data;

  const [t] = useTranslation();
  const [isVisibleModalStop, setIsVisibleModalStop] = useState(false);
  const [isVisibleModalLimitStop, setIsVisibleModalLimitStop] = useState(false);
  const streamingChannel = useRef<any>();

  useEffect(() => {
    createSubscription();
    return disconnectSocket;
  }, []);

  const handleReceivedMessage = async (message: { streamer: IStreamer; status: SocketStatus }) => {
    if (infoIdolLive) {
      if (message?.streamer?.id === infoIdolLive?.streamer_id) {
        if (message.status === SocketStatus.INACTIVE_STREAMER) {
          if (message.streamer.status !== StatusStreamer.STREAMING) {
            await agoraIdolManager.stop();
            storage.removeItem("infoIdolLive");
            setIsVisibleModalStop(true);
          }
        } else if (message.status === SocketStatus.EXPIRED_STREAMING_TIME) {
          if (message.streamer.status !== StatusStreamer.STREAMING) {
            await agoraIdolManager.stop();
            storage.removeItem("infoIdolLive");
            setIsVisibleModalLimitStop(true);
          }
        }
      }
    } else if (infoUserLive) {
      if (message.streamer.id === +infoUserLive?.streamer_id && message.streamer.status !== StatusStreamer.STREAMING) {
        sessionStorage.removeItem("infoUserLive");
        agoraUserManager.stop(+infoUserLive?.streamer_id);
      }
    }
  };
  const createSubscription = () => {
    if (infoIdolLive || infoUserLive) {
      streamingChannel.current = socket.actionCable?.subscriptions.create(
        { channel: "StreamingChannel" },
        { received: handleReceivedMessage }
      );
    }
  };
  const disconnectSocket = () => {};
  const handleDisabledLive = () => {
    logout();
  };
  const handleTimeOut = () => {};

  return (
    <>
      {isVisibleModalStop && (
        <Modal
          isVisibleModal={isVisibleModalStop}
          className="alert-cancel-modal"
          cancelText={t("btn.disabled")}
          onOk={handleDisabledLive}
          onCancel={handleDisabledLive}
        >
          <div className="alert-cancel-content">
            <div className="alert-cancel-text">{t("alert.disabled")}</div>
          </div>
        </Modal>
      )}
      {isVisibleModalLimitStop && (
        <Modal
          isVisibleModal={isVisibleModalLimitStop}
          className="alert-cancel-modal"
          cancelText={t("btn.disabled")}
          onOk={handleTimeOut}
          onCancel={handleTimeOut}
        >
          <div className="alert-cancel-content">
            <div className="alert-cancel-text">{t("alert.serverStop")}</div>
          </div>
        </Modal>
      )}
    </>
  );
}
