import LoadingApi from "components/LoadingApi";
import { setResourceI18n } from "i18n/i18n";
import ChatDetails from "pages/Chat/ChatDetails";
import ChatFillName from "pages/Chat/ChatFillName";
import ChatHistory from "pages/Chat/ChatHistory";
import ChatNew from "pages/Chat/ChatNew";
import InfoCompany from "pages/InfoCompany";
import { lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";

const Login = lazy(() => import("pages/Login"));
const SignUp = lazy(() => import("pages/SignUp"));
const Home = lazy(() => import("pages/Home"));
const Profile = lazy(() => import("pages/Profile"));
const ProfileBackground = lazy(() => import("pages/ProfileBackground"));
const FAQ = lazy(() => import("pages/FAQ"));
const Terms = lazy(() => import("pages/Terms"));
const PreLiveStream = lazy(() => import("pages/PreLiveStream"));
const LiveStreamIdol = lazy(() => import("pages/LiveStreamIdol"));
const LiveStreamUser = lazy(() => import("pages/LiveStreamUser"));
const HistoryLiveStream = lazy(() => import("pages/HistoryLiveStream"));
const HistoryWithHashtags = lazy(() => import("pages/HistoryLiveStream/HistoryWithHashtags"));

export default function AppWrapper() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setResourceI18n().then(() => {
      setReady(true);
    });
  }, []);

  if (!ready) return <LoadingApi isLoading={true} />;

  return (
    <div className="root-wrapper">
      <Routes>
        <Route path="/" element={<AuthWrapper />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile-background" element={<ProfileBackground />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/company" element={<InfoCompany />} />
          <Route path="/pre-live-stream" element={<PreLiveStream />} />
          <Route path="/live-stream-idol" element={<LiveStreamIdol />} />
          <Route path="/live-stream-user/:id" element={<LiveStreamUser />} />
          <Route path="/history-stream-user/:id" element={<HistoryLiveStream />} />
          <Route path="/history-stream-with-hashtags" element={<HistoryWithHashtags />} />
          <Route path="/chat-fill-name" element={<ChatFillName />} />
          <Route path="/chat-history" element={<ChatHistory />} />
          <Route path="/chat-new" element={<ChatNew />} />
          <Route path="/chat-details" element={<ChatDetails />} />
        </Route>
      </Routes>
    </div>
  );
}
