import { Button } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { PlayCircleOutlined } from "@ant-design/icons";
import { acceptedImageType, acceptedVideoType } from "constants/staticData";

interface IDataItem {
  url: string;
  type: string;
}

interface Props {
  data: Array<IDataItem>;
  itemSize?: number | string;
  isWrapped?: boolean;
  isDeletable?: boolean;
  handleDelete?(indexToDelete: number): void;
}

export const ChatImagesVideosList = ({
  data,
  itemSize = 95,
  isWrapped = false,
  isDeletable = false,
  handleDelete,
}: Props) => {
  const renderBtnClose = (dataIndex: number) => {
    return (
      <Button
        onClick={() => {
          if (handleDelete) {
            handleDelete(dataIndex);
          }
        }}
        className={styles.imgContClose}
        size="small"
        shape="circle"
      >
        X
      </Button>
    );
  };
  return (
    <div
      className={classNames(styles.container, {
        [styles[`container--isWrapped`]]: !!isWrapped,
      })}
    >
      {data.map((dataItem, dataIndex) => {
        if (acceptedImageType.includes(dataItem?.type)) {
          return (
            <div key={`${dataIndex}`} className={styles.imgWrapper}>
              {dataItem?.url ? (
                <img
                  className={styles.imgCont}
                  style={{ height: itemSize, width: itemSize }}
                  alt=""
                  src={dataItem?.url}
                />
              ) : (
                <div className={styles.imgCont} style={{ height: itemSize, width: itemSize }} />
              )}
              {isDeletable && renderBtnClose(dataIndex)}
            </div>
          );
        }
        if (acceptedVideoType.includes(dataItem?.type)) {
          return (
            <div key={`${dataIndex}`} className={styles.imgWrapper}>
              <video className={styles.imgCont} style={{ height: itemSize, width: itemSize }} src={dataItem?.url} />
              <Button className={styles.imgPlayPause} shape="circle" icon={<PlayCircleOutlined />} />
              {isDeletable && renderBtnClose(dataIndex)}
            </div>
          );
        }
        return (
          <div key={`${dataIndex}`} style={{ height: itemSize, width: itemSize }}>
            Wrong format
          </div>
        );
      })}
    </div>
  );
};
