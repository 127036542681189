import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { history } from "App";

interface IHeader {
  title?: string;
  imgTitle?: string;
  iconLeft?: string;
  iconRight?: string;
  onPressTitle?(): void;
  onPressLeft?(): void;
  onPressRight?(): void;
  hasShadow?: boolean;
  RightComponent?: any;
}

export default function PageHeader(props: IHeader) {
  const {
    title,
    imgTitle,
    iconLeft,
    iconRight,
    onPressLeft,
    onPressRight,
    onPressTitle,
    hasShadow = false,
    RightComponent,
  } = props;

  const renderLeft = () => {
    return (
      <div className={styles.btnActionWrapper} onClick={onPressLeft}>
        {iconLeft && onPressLeft ? <img src={iconLeft} alt="Header left icon" /> : <div className={styles.divEmpty} />}
      </div>
    );
  };

  const renderTitle = () => {
    const getTitleComponent = () => {
      if (imgTitle)
        return (
          <img
            src={imgTitle}
            alt="Header title icon"
            onClick={() => {
              if (onPressTitle) {
                onPressTitle();
                return;
              }
              history.push("/");
            }}
          />
        );
      if (title) return <span className={styles.txtTitle}>{title}</span>;
      return <div className={styles.divEmpty} />;
    };
    return (
      <div
        className={classNames(styles.btnActionWrapper, {
          [styles[`btnActionWrapper--hasRightComponent`]]: !!RightComponent,
        })}
      >
        {getTitleComponent()}
      </div>
    );
  };

  const renderRight = () => {
    if (RightComponent) {
      return RightComponent;
    }
    return (
      <div className={styles.btnActionWrapper} onClick={onPressRight}>
        {iconRight && onPressRight ? (
          <img src={iconRight} alt="Header right icon" />
        ) : (
          <div className={styles.divEmpty} />
        )}
      </div>
    );
  };

  return (
    <div className={classNames(styles.headerWrapper, { [styles[`headerWrapper--shadow`]]: !!hasShadow })}>
      {renderLeft()}
      {renderTitle()}
      {renderRight()}
    </div>
  );
}
