import { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import styles from "./styles.module.scss";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import useProfile from "utils/hooks/useProfile";
import { logoutAPI } from "api/authentication";
import { logout } from "utils/helper/authentication";
import Modal from "components/Modal";
import { LocalStorageKey } from "constants/enum";
import storage from "utils/helper/storage";
import { stopStreaming } from "api/liveStream";
import agoraIdolManager from "utils/agora/agoraIdolManager";

export default function SideNav() {
  const { refetchProfile } = useProfile();
  const { t } = useTranslation();
  const { collapsed, toggleSideNav } = useToggleSideNav();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("1");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const access_token = Cookies.get("access_token");

  const routes = useMemo(() => {
    const chatUserName = localStorage.getItem(LocalStorageKey.CHAT_USERNAME);
    if (access_token) {
      return [
        {
          key: "0",
          text: "",
          url: "/",
        },
        {
          key: "1",
          text: t("sideNav.basicInfo"),
          url: "/profile",
        },
        {
          key: "2",
          text: t("sideNav.streamingAvaSettings"),
          url: "/profile-background",
        },
        {
          key: "3",
          text: t("sideNav.chat"),
          url: "/chat-history",
        },
        {
          key: "4",
          text: t("sideNav.frequentlyAskedQuestions"),
          url: "/faq",
        },
        {
          key: "5",
          text: t("sideNav.terms"),
          url: "/terms",
        },
        {
          key: "6",
          text: t("sideNav.logout"),
        },
      ];
    }
    return [
      {
        key: "0",
        text: "",
        url: "/",
      },
      !access_token && !!chatUserName
        ? {
            key: "1",
            text: t("sideNav.chat"),
            url: "/chat-history",
          }
        : {
            key: "1",
            text: t("sideNav.chat"),
            url: "/chat-fill-name",
          },
      {
        key: "2",
        text: t("sideNav.frequentlyAskedQuestions"),
        url: "/faq",
      },
      {
        key: "3",
        text: t("sideNav.terms"),
        url: "/terms",
      },
    ];
  }, [access_token]);

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
    });
  }, [location.pathname]);
  const handleConfirmLogout = () => {
    setIsVisibleModal(true);
  };
  const handleLogout = async () => {
    try {
      const infoIdolLive = storage.getItem("infoIdolLive")?.data;
      if (infoIdolLive) {
        agoraIdolManager.stop();
        await stopStreaming();
        storage.removeItem("infoIdolLive");
      }
      await logoutAPI();
      logout({ isFrom401: true });
      toggleSideNav();
      setIsVisibleModal(false);
    } catch (err) {
      setIsVisibleModal(false);
    }
  };

  return (
    <div className={styles.container} style={{ width: collapsed ? 0 : "100%" }}>
      <div className={styles.leftPart}>
        {/* <div className={styles.backdropSmall} onClick={toggleSideNav} /> */}
        <div className={styles.backdropNormal}>
          <div className={styles.sideNav} style={{ width: collapsed ? 0 : "60%", transition: "width 0.2s" }}>
            {!collapsed && (
              <Menu className={styles.sideNavItemWrapper} selectedKeys={[selectedKey]} defaultOpenKeys={[]}>
                {routes
                  .filter((route) => !!route.text)
                  .map((route) => {
                    return (
                      <Menu.Item className={styles.sideNavItem} key={route.key}>
                        {route.url && (
                          <Link
                            to={route.url}
                            onClick={() => {
                              if (route.url.includes("profile")) {
                                refetchProfile();
                              }
                              toggleSideNav();
                            }}
                          >
                            {route.text}
                          </Link>
                        )}
                        {route.key === "6" && <div onClick={handleConfirmLogout}>{route.text}</div>}
                      </Menu.Item>
                    );
                  })}
              </Menu>
            )}
          </div>
        </div>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.backdropLarge} onClick={toggleSideNav} />
      </div>
      {isVisibleModal && (
        <Modal
          isVisibleModal={isVisibleModal}
          setIsVisibleModal={setIsVisibleModal}
          isTwoOption
          okText={t("modal.yesBtn")}
          cancelText={t("modal.noBtn")}
          onOk={handleLogout}
          className="alertTextModal"
        >
          <div className="modalText">
            <div className="modalTextContent">{t("warning.logout")}</div>
          </div>
        </Modal>
      )}
    </div>
  );
}
