import { history } from "App";
import ImagesObj from "assets/images";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FooterItem from "./FooterItem";
import styles from "./styles.module.scss";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { PlatformType } from "constants/enum";
import { getPlatform } from "utils/helper/browserAction";
import { useLocation } from "react-router-dom";

interface IPageFooter {
  customClassName?: any;
  customStyles?: any;
}

export default function PageFooter(props: IPageFooter) {
  const location: any = useLocation();
  const isNewAccount = !!location?.state?.isNewAccount;
  const { customClassName, customStyles } = props;
  const { t } = useTranslation();
  const platform = getPlatform();
  const keyboardOpenFromLib = useDetectKeyboardOpen();
  const isInTopPage = history.location.pathname === "/";

  const onClickRoomy = () => {
    if (!isNewAccount && !isInTopPage) {
      history.push("/");
    }
  };
  const onClickFAQ = () => {
    history.push("/faq");
  };
  const onClickProfile = () => {
    history.push("/company");
  };
  const onClickTerms = () => {
    history.push("/terms");
  };
  const onClickTwitter = () => {
    window.open("https://twitter.com/roomy48051514", "_blank", "noopener,noreferrer");
  };
  const onClickInstagram = () => {
    window.open("https://www.instagram.com/roomy_live/", "_blank", "noopener,noreferrer");
  };

  return (
    <div
      className={classNames(
        styles.footerWrapper,
        { [styles["footerWrapper--keyboardShown"]]: !!keyboardOpenFromLib && platform === PlatformType.ANDROID },
        customClassName
      )}
      style={customStyles}
    >
      <FooterItem title={t("footer.roomy")} onClick={onClickRoomy} />
      <FooterItem title={t("footer.frequentlyAskedQuestions")} onClick={onClickFAQ} />
      <FooterItem title={t("footer.companyProfile")} onClick={onClickProfile} />
      <div className={styles.footerTxtAndIcons}>
        <FooterItem title={t("footer.termsOfService")} onClick={onClickTerms} />
        <div className={styles.footerIconsWrapper}>
          <FooterItem image={ImagesObj.TwitterIcon} imageWidth={20} imageHeight={16} onClick={onClickTwitter} />
          <FooterItem image={ImagesObj.InstagramIcon} imageWidth={20} imageHeight={20} onClick={onClickInstagram} />
        </div>
      </div>
      <div className={styles.footerCompanyName}>
        <div className={styles.footerTxt}>{t("footer.primaveraCoLtd")}</div>
      </div>
    </div>
  );
}
