import SideNav from "components/SideNav";
import styles from "./styles.module.scss";
import { useOutlet } from "react-router-dom";
import useScroll from "utils/hooks/useScroll";
import { useEffect } from "react";
import disableScroll from "disable-scroll";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import { LocalStorageKey } from "constants/enum";
import useUID from "utils/hooks/useUID";

export default function PageWrapper() {
  const { collapsed } = useToggleSideNav();
  const outlet = useOutlet();
  const isUIDExist = !!localStorage.getItem(LocalStorageKey.UID);
  const { isFooterShown } = useScroll();
  const { generateUID, loadingUID } = useUID();

  useEffect(() => {
    if (!collapsed) {
      disableScroll.on();
      return;
    }
    disableScroll.off();
  }, [collapsed]);
  if (!isUIDExist && !loadingUID) {
    generateUID();
  }

  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div className={styles.mainWrapper}>
        <div className={styles.pageContent} style={{ marginBottom: isFooterShown ? 125 : 0 }}>
          {outlet}
        </div>
      </div>
    </div>
  );
}
